import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '$api';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
})
export class LogoutModalComponent implements OnInit, OnDestroy {
  public logoutTimer$: Subscription; // Holds the countdown observable
  public counter: number; // Log out after this many seconds

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public dataAlt: any,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.counter = this.data.modalDuration; // How long to display the modal window

    // Create a timer observable that counts down
    this.logoutTimer$ = interval(1000).subscribe(() => {
      // If timer is greater than 0, count down.
      if (this.counter > 1) {
        this.counter--;
      } else {
        const abandonedLoanStatusUpdateEnabled = this.data.settings.config["AbandonedLoanStatusUpdate.Enabled"]?.value || 0;
        if(abandonedLoanStatusUpdateEnabled)
        {
          this.handleBorrowerInactivity()
        }
        // If timer hits zero or below, CLOSE this modal which toggles the logout action in AuthService
        this.logout();
      }
    });
  }

  /** Log the user out manually */
  public logout() {
    this.dialogRef.close(true);
  }

  public ngOnDestroy() {
    this.logoutTimer$.unsubscribe(); // Unsub from timer on modal close
  }

  public handleBorrowerInactivity() {
    const idleTimeout = this.data.sessionExpirationSeconds;
    this.api.borrowerInactivity.handleBorrowerInactivity(idleTimeout).subscribe();
  }
}
