import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '$api';
import { AppSettings, AnalyticsService } from '$shared';
import { Router } from '@angular/router';
import { map, debounceTime, tap, catchError } from 'rxjs/operators';
import { LoanUtils, MilestoneStateEnum } from '../../shared/utils/loan-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalsService } from '$modals';
import { ApplicationService } from '../application/shared/services/application.service';
import { UIStoreService } from '$ui';
import { ApplicationNavigationService } from '../../shared/services/application-navigation.service';
import { environment } from '$env';
import { LoggingService } from 'src/app/shared/services/logging.service';
import {
  CPOSClientLogLevelEnum,
  ICPOSLogEntry,
  ILoanSnapshotViewModel,
  MilestoneStatusTypeEnum,
  LoanPurposeTypeEnum,
  ILeadSourceInfoViewModel
 } from 'src/app/shared/models';
import { isNullOrWhitespace } from 'src/app/shared/utils/data-validation-util';

interface LoanSnapshot {
  loanPurposeString: string;
  loanStatusString: string;
  loanTitle: string;
  canContinue: boolean;
  showDecisionedText: boolean;
  loanStatusDescription: string;
}

@UntilDestroy()
@Component({
  selector: 'app-my-loans',
  templateUrl: './my-loans.component.html',
  styleUrls: ['./my-loans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyLoansComponent implements OnInit, OnDestroy {

  /** Used in template to show loading state */
  isLoading = false;
  isOpenLoanDisabled = false;
  isBusy$ = this.app.isSaving$.pipe(debounceTime(500));
  /** Show errors in the UI */
  error: string;
  /** All loan snapshots */
  loanCount: number;
  loanSnapShots: ILoanSnapshotViewModel[];
  loanSnapshotsApiState$ = this.api.select.activeLoanSnapshots$;
  loanSnapshots$ = this.api.getApiStoreData(this.loanSnapshotsApiState$)
    .pipe(
      map(loanSnapshots => {
      this.loanCount = loanSnapshots.length;
      this.loanSnapShots = loanSnapshots;
      return loanSnapshots.map((loanSnapshot => this.updateLoanSnapshot(loanSnapshot)));
      })
    );
    canAddNewLoan: boolean;

  constructor(
    private api: ApiService,
    private app: ApplicationService,
    private settings: AppSettings,
    private router: Router,
    private ref: ChangeDetectorRef,
    private log: LoggingService,
    private modals: ModalsService,
    private ui: UIStoreService,
    private analytics: AnalyticsService,
    private appNavService: ApplicationNavigationService
  ) {
  }

  ngOnInit() {
    this.api.activeLoanSnapshots.get().subscribe();

    this.loanSnapshots$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.settings.loanApplicationId) this.api.appState.get().subscribe();
    }); 

    this.ui.select.config$.subscribe(config => {
      if (config && config['config.ViewMyLoans.EnableAddaNewLoan']) {
        this.canAddNewLoan = <boolean>config['config.ViewMyLoans.EnableAddaNewLoan'].value;
      }
    });
  }

  // Required for untilDestroyed
  ngOnDestroy() {

  }

  /**
   * Update loan snapshots with extra data needed to show in UI
   * @param loanSnapshot
   */
updateLoanSnapshot(loanSnapshot: ILoanSnapshotViewModel): LoanSnapshot & ILoanSnapshotViewModel {

    const loanStatusEnum = LoanUtils.getMilestoneState(loanSnapshot.currentMilestone);
    let loanPurposeString: string;
    let loanTitle: string;
    let loanStatusString: string;
    let canContinue = true;
    let showDecisionedText = false;
    let loanStatusDescription: string;

    // Get Loan Status and determine text for button
    switch (loanStatusEnum) {
      case MilestoneStateEnum.Application:
      case MilestoneStateEnum.Documentation:
        canContinue = true;
        break;
      case MilestoneStateEnum.Closing:
        canContinue = false;
        break;
      case MilestoneStateEnum.Inactive:
      default:
        showDecisionedText = true;
        canContinue = false;
        break;
    }

    // Mapping loan title
    switch (loanSnapshot.currentMilestone) {
      case MilestoneStatusTypeEnum.Prospect:
      case MilestoneStatusTypeEnum.PreApproved:
        loanTitle = 'Application';
        break;
      case MilestoneStatusTypeEnum.Incomplete:
      case MilestoneStatusTypeEnum.AppCompleted:
      case MilestoneStatusTypeEnum.Processing:
      case MilestoneStatusTypeEnum.Underwriting:
      case MilestoneStatusTypeEnum.Approved:
      case MilestoneStatusTypeEnum.DocsOut:
        loanTitle = 'Documentation';
        break;
      case MilestoneStatusTypeEnum.Funded:
      case MilestoneStatusTypeEnum.Completed:
        loanTitle = 'Funded';
        break;
      case MilestoneStatusTypeEnum.Adverse:
      case MilestoneStatusTypeEnum.Cancelled:
        loanTitle = 'Inactive';
        break;
      default:
        console.warn(`Could not find a match to map MilestoneStatusTypeEnum:`, loanSnapshot.currentMilestone);
        loanTitle = 'Application';
    }

    // Mapping consumer site Loan Status
    if (this.settings.config && this.settings.config['cPOS.OverrideConsumerSiteDefaultLoanStatus'].value === true
      && !isNullOrWhitespace(loanSnapshot.customMilestoneStatusCS)) {
        loanStatusString = loanSnapshot.customMilestoneStatusCS;
    } else {
      switch (loanSnapshot.currentMilestone) {
        case null:
        case MilestoneStatusTypeEnum.Prospect:
        case MilestoneStatusTypeEnum.PreApproved:
          loanStatusString = 'Application';
          break;
        case MilestoneStatusTypeEnum.Approved:
          loanStatusString = 'Approved';
          break;
        case MilestoneStatusTypeEnum.Processing:
        case MilestoneStatusTypeEnum.Underwriting:
          loanStatusString = 'Processing';
          break;
        case MilestoneStatusTypeEnum.Incomplete:
        case MilestoneStatusTypeEnum.AppCompleted:
          loanStatusString = 'Disclosures Pending';
          break;
        case MilestoneStatusTypeEnum.DocsOut:
          loanStatusString = 'Sign Docs';
          break;
        case MilestoneStatusTypeEnum.Funded:
        case MilestoneStatusTypeEnum.Completed:
          loanStatusString = 'Funded';
          break;
        case MilestoneStatusTypeEnum.Adverse:
        case MilestoneStatusTypeEnum.Cancelled:
          loanStatusString = 'Decisioned';
          loanStatusDescription = 'A decision has been reached. Please contact your loan officer for details.';
          break;
        default:
          console.warn(`Could not find a match to map the loan status:`, loanSnapshot.currentMilestone);
          loanStatusString = 'Application';
      }
    }

    // Get string for loan purpose
    switch (loanSnapshot.loanPurpose) {
      case LoanPurposeTypeEnum.Refinance:
        loanPurposeString = 'Refinance';
        break;
      default:
      case LoanPurposeTypeEnum.Purchase:
        loanPurposeString = 'Purchase';
        break;
    }

    return Object.assign({}, loanSnapshot, {
      loanPurposeString,
      loanTitle,
      loanStatusString,
      canContinue,
      showDecisionedText,
      loanStatusDescription
    });
  }

  /**
   * Open the selected loan
   * @param loanSnapshot
   */
  openLoan(loanSnapshot: ILoanSnapshotViewModel): void {
    // Avoid clicking multiple times before modal appears
    if (this.isOpenLoanDisabled) {
      return;
    }
    this.isOpenLoanDisabled = true;

    // Clear 1003 form data
    this.ui.form1003 = null;

    // Store loan IDs
    this.settings.loanId = loanSnapshot.loanId;
    this.settings.loanApplicationId = loanSnapshot.loanApplicationId;
    // TFS 316267: LO Reassignment Enhancement
    // Reset original LO ID so that a new one can be stored when megaLoan.get() is called
    this.settings.loUserIdOriginal = null;

    this.setMixPanelLoanIdValues();

    // Load loan
    this.api.megaLoan.get(true, true).subscribe((loan) => {
      // Make sure the appState is hydrated
      this.api.appState.get().subscribe();
      this.api.auditLog.borrowerOpenedLoan(loanSnapshot.loanId);
      if (environment.endpoints.logging) {
        this.log.track<ICPOSLogEntry>({
          eventTime: new Date(),
          logLevel: CPOSClientLogLevelEnum.Info,
          message: `LoanId: ${loanSnapshot.loanId}. Page: my-loans.component.ts: User ${this.settings.userId}. Subject Property Address => ${LoanUtils.getSubjectProperty(loan).streetName}`,
          metadata: {
            routePath: this.router.url
          },
        });
      }
      /* If the loan is locked by different borrower
          open the loan lock modal
      */
      const isLockedByOtherBorrower = LoanUtils.isLockedByOtherBorrower(loan, this.settings);
      const isLockedLimitActionsByCurrentUser= LoanUtils.isLockedLimitActionsByCurrentUser(loan, this.settings);
      if (isLockedByOtherBorrower || isLockedLimitActionsByCurrentUser) {
        this.modals.open('LoanLockModalComponent', false, 'lg', {
          loanAccessByBorrowerFullName: LoanUtils.getLoanAccessByBorrowerFullName(loan),
        }, null, { disableClose: true });
      } else {
        this.api.appState.get().subscribe((appState) => {
          const loanStatusEnum = LoanUtils.getMilestoneState(loanSnapshot.currentMilestone, appState);
          const completedDate = appState && appState.form1003 ? appState.form1003.completedDate : null;

          console.warn(`MyLoansComponent::openLoan -> currentMilestone: ${loanSnapshot.currentMilestone}, completedDate: ${completedDate}, loanStatusEnum: ${loanStatusEnum}`);
          // Keep switch statement under else block to avoid routing before opening modal
          switch (loanStatusEnum) {
            case MilestoneStateEnum.Application:
              this.router.navigate(['/'], { queryParamsHandling: 'merge' });
              break;
            case MilestoneStateEnum.Documentation:
              this.router.navigate(['/tasks'], { queryParamsHandling: 'merge' });
              break;
            case MilestoneStateEnum.Closing:
              this.router.navigate(['/tasks/loan-details']);
              break;
            default:
              this.router.navigate(['/tasks'], { queryParamsHandling: 'merge' });
              break;
          }
        });
      }
      this.isOpenLoanDisabled = false;
    });

  }

  private setMixPanelLoanIdValues() {
    //reset mixpanel super properties of loanId
    this.analytics.removeMixPanelProperty('Loan Id');
    this.analytics.removeMixPanelProperty('CV Guid');

    this.analytics.mixpanelSuperProps({ 'Loan Id': this.settings.loanId });
    this.analytics.mixpanelSuperProps({ 'CV Guid': this.settings.loanId });
  }

  setError(error: string = null): void {
    this.error = error;
    this.ref.markForCheck();
  }

  /**
   * Run LSID assignment rules
   * navigate to Milestone page if rule passes
   * else show modal window
   * */
  onApplyNewLoan(): void {
    const mixPanelProps: { [key: string]: string } = {};
    for (let i = 0; i < this.loanCount; i++) {
      mixPanelProps[`LoanId ${i + 1}`] = this.loanSnapShots[i].loanId;
      mixPanelProps[`Loan Purpose ${i + 1}`] = LoanPurposeTypeEnum[this.loanSnapShots[i].loanPurpose];
      mixPanelProps[`Loan Status ${i + 1}`] = MilestoneStatusTypeEnum[this.loanSnapShots[i].currentMilestone];
    }

    this.api.leadSourceForNewLoan.get().pipe(
      tap((broker: ILeadSourceInfoViewModel) => {
        if (!broker) {
          this.modals.open('ConfirmationNewLoanModalComponent', false, 'lg', true, null, { disableClose: false })
          .afterClosed()
          .subscribe((dismiss: boolean) => {
            if (dismiss) {
              console.log(`New loan can not be created due to no LO found`);
            }
          });
        } else {
          this.settings.lsid = broker.leadSourceId;
          this.settings.lsidUserId = broker.userAccountId.toString();
          this.settings.loUserIdOriginal = null;
          this.api.services.guidsGet(1).pipe(
            tap(guidList => {
              if (guidList && guidList.length) {
                this.settings.loanId = guidList[0];
                this.settings.loanApplicationId = guidList[0];
                this.settings.ui = null;
                this.ui.form1003 = null;
                this.settings.canSaveAppState = false;

                this.setMixPanelLoanIdValues();

                this.analytics.trackEvent('Add New Loan', mixPanelProps);

                // Bug: 412610 - PS - GLOBAL- CPOS- "Apply for new loan" button redirects to previous loan - Loan# n/a- ZD# 25517
                this.api.megaLoan.resetCache(); // Clear out any cached loan

                this.appNavService.appRouting$.next({ sectionId: null, pageId: null });
                this.router.navigate([`/`]);
              } else {
                this.setError(`An error occurred. Please try again.`);
              }
            }),
          ).subscribe();
        }
      }),
      catchError(error => error)
    ).subscribe();
  }

  /**
   * This may come as a surprise to you but... this function creates a new loan
   */
  createNewLoan(): void {
    // Reset an existing error messages
    this.setError();

    // use loanId provided during login
    if (this.settings.loanId) {
      this.router.navigate([`/`]);
    } else {
      // Get a new GUID; use it to create a new loan
      this.api.services.guidsGet(1).subscribe(guidList => {
        if (guidList && guidList.length) {
          this.settings.loanId = guidList[0];
          this.router.navigate([`/`]);
        } else {
          this.setError(`An error occurred. Please try again.`);
        }
      });
    }
  }
}
