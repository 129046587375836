// This file has been has been automatically generated
// To extend an interface, create a file that exports the same interface name within the same module name with ONLY the ADDITIONAL properties.
// TypeScript will automatically merge both interfaces together.

// DO NOT MODIFY

export enum AccountNameEnum {
Default = 0,
Buyer = 1,
Seller = 2,
Ghost = 3,
}

export enum AccountOwnershipTypeEnum {
AuthorizedUser = 0,
Comaker = 1,
Individual = 2,
JointContractualLiability = 3,
JointParticipating = 4,
Maker = 5,
OnBehalfOf = 6,
Terminated = 7,
Undesignated = 8,
Deceased = 9,
None = -1,
}

export enum AccountStatusEnum {
Unknown = 0,
Active = 1,
Locked = 2,
Temporary = 3,
}

export enum AccountTypeEnum {
Default = 0,
Asset = 1,
Liability = 2,
Income = 3,
Expense = 4,
Capital = 5,
}

export enum ActionTypeEnum {
None = 0,
RunCredit = 1,
LockRequest = 2,
CreateInitialDisclosures = 3,
CreateInitialLockDisclosures = 4,
AdverseRequest = 5,
LoanStatusSetToCancel = 6,
ConsumerSiteSaveAndContinueAfterCreditSuccess = 7,
RunComplianceCheck = 8,
MegaSave = 9,
ExportToLos = 10,
UpdateLos = 11,
DiscloseInLos = 12,
DiscloseInLosDontExport = 13,
DiscloseInLosExport = 14,
OpeningRequest = 15,
XpressProcessing = 16,
DuplicateLoan = 17,
CreateBrokerDisclosures = 18,
RunAUS = 19,
Register = 20,
ReceivedInitialDisclosures = 21,
LenderData = 22,
AppraisalReceived = 23,
PostLock = 24,
PreviewDisclosures = 25,
AppraisalOrder = 26,
CreateInitialDisclosuresInPerson = 27,
AdverseDocument = 28,
CreateSelectedDisclosures = 29,
ConsumerSiteInitiateVoa = 30,
GenerateVoaReport = 31,
VOEInstantReceived = 32,
VOEReverifyReceived = 33,
VOIInstantReceived = 34,
VOIReverifyReceived = 35,
RegisterCreateAccount = 36,
}

export enum ActiveSystemEnum {
None = 0,
LOS = 1,
iMP = 2,
}

export enum AddressTypeEnum {
Default = 0,
Present = 1,
Former = 2,
Mailing = 3,
Billing = 4,
GovernmentMonitoring = 5,
RealEstate = 6,
}

export enum AdverseDenialReasonTypeEnum {
BankruptcyPastOrPresent = 1,
CollectionActionOrJudgment = 2,
CreditApplicationIncomplete = 4,
CreditCannotBeGrantedUnderTermsRequested = 8,
CreditObligationsWithOthers = 16,
ForeclosureRepossession = 32,
IncomeExcessiveObligations = 64,
LengthOfEmployment = 128,
LimitedCreditExperience = 256,
CLTVSubordination = 512,
NetTangibleBenefit = 1024,
OccupancyVacancy = 2048,
CollateralNotSufficient = 4096,
CreditExcessiveObligations = 8192,
CreditUnacceptablePaymentRecord = 16384,
CreditLackCashReserves = 32768,
CreditGarnishmentAttachment = 65536,
CreditInsufficientNumberCreditReferences = 131072,
CreditNoCreditFile = 262144,
CreditNumberRecentInquiries = 524288,
CreditPoorCreditPerformance = 1048576,
CreditUnableVerifyReferences = 2097152,
CreditUnnaceptableTypeReferences = 4194304,
EmploymentTemporaryIrregular = 8388608,
EmploymentUnableVerify = 16777216,
IncomeInsufficient = 33554432,
IncomeUnableVerify = 67108864,
MortgageInsuranceDenied = 134217728,
OtherInsufficientFunds = 268435456,
OtherUnnaceptableProperty = 536870912,
OtherInsufficientDataProperty = 1073741824,
OtherUnacceptableAppraisal = 2147483648,
OtherUnacceptableLeaseholdEstate = 4294967296,
OtherSpecify = 8589934592,
ResidencyLength = 17179869184,
ResidencyTemporary = 34359738368,
ResidencyUnableVerify = 68719476736,
}

export enum AdverseReasonTypeEnum {
None = 0,
ApplicationRecommendedForDenial = 1,
ApplicationWithdrawnByBorrower = 2,
PreApprovalRequestDenied = 4,
PreApprovalRequestApprovedButNotAccepted = 5,
ApplicationApprovedButNotAccepted = 6,
ApplicationDenied = 7,
ApplicationWithdrawn = 8,
FileClosedForIncompleteness = 9,
LoanPurchasedByYourInstitution = 10,
PreapprovalRequestDeniedByFinancialInstitution = 11,
PreapprovalRequestApprovedButNotAccepted = 12,
}

export enum AdverseRequestReceivedViaTypeEnum {
None = 0,
Phone = 1,
Email_Text = 2,
Mail = 3,
Email = 4,
Text = 5,
FaceToFace = 6,
}

export enum AdverseStatusEnum {
None = 0,
Requested = 1,
Pending = 2,
Created = 3,
Sent = 4,
Failed = 5,
}

export enum AdverseWithdrawnReasonTypeEnum {
CompetitorOfferedLowerRateBetterTerms = 1,
HomeForSale = 2,
NoReasonProvided = 4,
Other = 8,
PurchasedAgreementCancelled = 16,
ServiceUnsatisfactory = 32,
UnexpectedLifeEvent = 64,
}

export enum AmortizationTypeEnum {
None = 0,
Fixed = 1,
ARM = 2,
GPM = 3,
Other = 4,
}

export enum AmountMethodEnum {
Manual = 0,
Itemized = 1,
Calculated = 2,
MonthlyMI = 3,
SinglePremiumNonRefundable = 4,
SinglePremiumRefundable = 5,
SplitPremiumNonRefundable = 6,
SplitPremiumRefundable = 7,
}

export enum AppraisalActionEnum {
New = 0,
Update = 1,
Cancel = 2,
Document = 3,
}

export enum AppraisalConditionTypeEnum {
AsIs = 1,
Other = 2,
SubjectToCompletion = 3,
SubjectToInspections = 4,
SubjectToRepairs = 5,
}

export enum AppraisalContactTypeEnum {
None = 0,
SellerAgent = 4,
BuyerAgent = 5,
PropertyManager = 6,
Others = 7,
Occupant = 8,
Owner = 9,
CoBorrower = -3,
Borrower = -2,
}

export enum AppraisalEventStatusEnum {
Undefined = 0,
AppraisalSubmissionAcceptedUCDP = 1,
AppraisalSubmissionUnsuccessful = 2,
AssignmentAccepted = 3,
Delayed = 4,
DocumentUploaded = 5,
InspectionComplete = 6,
InspectionScheduled = 7,
MessageReceived = 8,
OnHold = 9,
OrderCancelled = 10,
OrderChanged = 11,
OrderComplete = 12,
PendingQualityReview = 13,
Resumed = 14,
UCDPDocumentIdUpdated = 15,
OrderReceived = 16,
CopySentToBorrower = 17,
OrderNoteAdded = 18,
Assigned = 19,
}

export enum AppraisalOrderStatusEnum {
Assigned = 0,
AssignmentReady = 1,
InProgress = 2,
ReviewReady = 3,
Complete = 4,
ReworkRequired = 5,
Cancelled = 6,
Hold = 7,
Pending = 8,
InReview = 9,
AssignmentAccepted = 10,
InspectionScheduled = 11,
InspectionComplete = 12,
Delayed = 13,
RevisionNeeded = 14,
}

export enum AppraisalPaidByEnum {
Borrower = 0,
LoanOfficer = 1,
Broker = 2,
Seller = 3,
}

export enum AppraisalPaymentMethodEnum {
BankAccount = 0,
Check = 1,
CreditOrDebitCard = 2,
PTC = 3,
AMCToChargeCreditCard = 4,
AMCToInvoiceLender = 5,
}

export enum AppraisalRequestStatusEnum {
NeedToOrder = 0,
SentToHVM = 1,
SentToiMP = 2,
SentToLenderX = 3,
ConfirmedFromLenderX = 4,
Finnished = 5,
FinnishedWithException = 6,
}

export enum AppraisalRequestTypeEnum {
RequestAppraisal = 1,
OrderAppraisal = 2,
SendUpdates = 3,
SendNotesAndDocuments = 4,
Hold = 5,
Resume = 6,
Cancel = 7,
ReleaseOrder = 8,
RequestRevision = 9,
CancelRevision = 10,
CancelHvm = 11,
GetAMCFields = 12,
}

export enum AssetTypeEnum {
Checking = 0,
Savings = 1,
CD = 2,
MutualFunds = 3,
Bonds = 4,
Stocks = 5,
MoneyMarket = 6,
RetirementFund = 7,
IRA = 8,
GiftFunds = 9,
EscrowDeposit = 10,
ProceedsfromaPropertySale = 11,
TrustFunds = 12,
LifeInsuranceCashValue = 13,
Other = 14,
Automobile = 15,
SecuredBorrowerFundsNotDeposited = 16,
BridgeLoan = 17,
CashOnHand = 18,
GiftOfEquity = 19,
NetEquity = 20,
NetWorthOfBusinessOwned = 21,
OtherLiquidAsset = 22,
OtherNonLiquidAsset = 23,
GiftedOtherNonLiquidAsset = 24,
IndividualDevelopmentAccount = 25,
StockOptions = 26,
Grant = 27,
EmployerAssistance = 28,
ProceedsFromSaleOfNonRealEstateAsset = 29,
RentCredit = 30,
SweatEquity = 31,
TradeEquity = 32,
UnsecuredBorrowedFunds = 33,
NotRequired = 99,
SelectOneFinancial = 100,
SelectOneOther = 101,
SelectOneGift = 102,
None = -1,
LotEquity = 34,
RelocationFunds = 35,
}

export enum AuditLogCategoryEnum {
UserAccount = 0,
LoanRelated = 1,
AppraisalRelated = 2,
LockRelated = 3,
}

export enum AuditLogLevelEnum {
None = 0,
Loan = 1,
LoanApplication = 2,
}

export enum AuditLogTypeEnum {
AgreedToTerms = 0,
ActivatedAccount = 1,
AcceptedESign = 2,
AcceptedCreditAuthorization = 3,
CreditReportRetrieved = 4,
UploadedDocuments = 5,
LockedRate = 6,
DisclosuresDelivered = 7,
AuthorizationFormsDelivered = 8,
ReviewedDisclosures = 9,
FaxedAuthorizations = 10,
CreatedLoan = 11,
CompletedActivity = 12,
ChangedProfile = 13,
LockRateRequested = 14,
FNMImported = 15,
eSignConsent = 16,
LoanStatusChanged = 17,
AppraisalOrderRequested = 18,
AppraisalOrderOrdered = 19,
AppraisalOrderDelivered = 20,
AppraisalOrderAcknowledged = 21,
ApplicationDateSet = 22,
LoanApplicationCompleted = 23,
InitialDisclosuresSent = 24,
LockRequested = 25,
LockFulfilled = 26,
TrackingDisclosures = 27,
SecureLinkSent = 28,
LockDenied = 29,
LockRegistrationConfirmed = 30,
LockCancellationRequested = 31,
LockCancelled = 32,
ExitWorkflow = 33,
BorrowerAddedRemoved = 34,
DocumentViewed = 35,
ReDisclosuresDelivered = 36,
TrackingReDisclosures = 37,
DocumentSigned = 38,
DocumentDeclinedToSign = 39,
DocumentPreviewed = 40,
ReDisclosuresSent = 41,
ClosingDisclosuresSent = 42,
MiscellaneousDocumentsSent = 43,
AssetEVerified = 44,
FicoScoreOverridden = 45,
LoanStatusUpdated = 46,
DuplicateLoanAlertAcknowledged = 47,
DisclosuresProcess = 48,
DisclosuresDetailedProcess = 49,
DocumentStatusChanged = 50,
AutoAssignLO = 60,
AutoAssignLoanData = 61,
ConcessionRequested = 62,
ConcessionApproved = 63,
ConcessionDeclined = 64,
AutoDisclosureQueueStatusChanged = 65,
AutoDisclosuresProcess = 66,
eConsentAccepted = 68,
eConsentDeclined = 69,
BorrowerLogin = 70,
UpdateLoanNumber = 71,
ReassignLoanOfficer = 72,
LegalDisclaimerConfirmed = 73,
AutomatedUnderwriterRequested = 74,
AutomatedUnderwriterResponseSuccessful = 75,
AutomatedUnderwriterResponseFailure = 76,
LoanRegistrationRequested = 77,
LoanRegistrationAccepted = 78,
LoanRegistrationError = 79,
LoanRegistration = 80,
CreateExternalLoanNumber = 81,
URLAConversion = 82,
AdditionalREOs = 83,
}

export enum AusTypeEnum {
NotSpecified = 0,
DU = 1,
LPA = 2,
InvestorAUS = 3,
ManualTraditional = 4,
GUS = 5,
DO = 6,
}

export enum AUSVerificationTypeEnum {
None = 0,
Day1Certainty = 1,
AIM = 2,
Both = 3,
}

export enum AuthenticationRequestTypeEnum {
CancelRequest = 0,
Authenticate = 1,
CreateAccount = 2,
UpdateAccount = 3,
Activate = 4,
AuthenticateEx = 5,
LookupToken = 6,
AuthenticateRealtorMobileApp = 7,
PassworReset = 8,
PasswordChange = 9,
RegisterAccount = 10,
MfaAuthenticate = 11,
MfaReRequest = 12,
}

export enum AuthenticationStatusEnum {
Unknown = 0,
ValidPassword = 1,
InvalidPassword = 2,
InvalidUserName = 3,
InActiveUser = 4,
AccountLocked = 5,
InvalidEmailOrSecurityAnswer = 6,
AccountLockFailed = 7,
PasswordExpired = 8,
ResetPasswordOnNextLogin = 9,
}

export enum AutoDisclosureDispositionEnum {
Undone = 0,
Cleared = 1,
}

export enum BankruptcyTypeEnum {
SelectOne = 0,
ChapterEleven = 1,
ChapterSeven = 2,
ChapterThirteen = 4,
ChapterTwelve = 8,
}

export enum BankStatementsIncomeTypeEnum {
FullDocumentation = 0,
VAStreamlineCredit = 1,
Months24 = 2,
FHAStreamlineCredit = 3,
FHAStreamlineNonCredit = 4,
VAStreamlineNonCredit = 5,
USDAStreamline = 6,
}

export enum BooleanTypeEnum {
False = 0,
True = 1,
Null = -1,
}

export enum BorrowerContextTypeEnum {
Borrower = 0,
CoBorrower = 1,
Both = 2,
}

export enum BorrowerNeedStatusEnum {
Needed = 1,
Review = 2,
Incomplete = 3,
Completed = 4,
Deleted = 5,
}

export enum BorrowerNeedUnderlyingTypeEnum {
Asset = 1,
Declaration = 2,
Income = 3,
Property = 4,
Liability = 5,
PublicRecordsOrJudgement = 6,
MiscellaneousExpense = 7,
MiscellaneousDocument = 8,
Borrower = 9,
}

export enum BorrowerTypeEnum {
Borrower = 1,
CoBorrower = 2,
}

export enum BorrowerViewableTypeEnum {
Enabled = 1,
Always = 2,
Never = 3,
Checked = 4,
}

export enum BusinessContactCategoryTypeEnum {
None = 0,
Title = 2,
Escrow = 3,
SellerAgent = 4,
BuyerAgent = 5,
PropertyManager = 6,
Others = 7,
Occupant = 8,
Owner = 9,
Seller = 10,
CoBorrower = -3,
Borrower = -2,
}

export enum BusinessContactCategoryTypeMask {
None = 0,
Title = 32,
Escrow = 64,
SellerAgent = 128,
BuyerAgent = 256,
PropertyManager = 512,
Others = 1024,
Seller = 8192,
}

export enum BusinessContactPhoneTypeEnum {
Home = 0,
Cell = 1,
Work = 2,
Other = 3,
Email = 4,
None = -1,
}

export enum CalculatedValueTypeEnum {
Unknown = 0,
LTV = 1,
DTIAndHousingRatio = 2,
CLTV = 3,
HCLTV = 4,
Apr = 6,
NetRentalIncome = 7,
MonthlyPayment = 8,
Hud801And802 = 9,
HousingExpenses = 10,
InterestAmountPerDiem = 11,
DetailsOfTransaction = 12,
RecalculatedCosts = 13,
FirstPaymentDate = 15,
AgregateAdjustment = 16,
VaFeeLimitsExceeded = 17,
NewMonthlyPayment = 18,
NetTangibleBenefit = 19,
QMCertification = 20,
LoanDecisionScore = 21,
FullyIndexedRate = 22,
IsImpoundMandatory = 23,
RecoupmentPeriod = 24,
FHACalculator = 25,
VACalculator = 26,
USDACalculator = 27,
AmortizationPayments = 28,
LiquidAssetReserves = 29,
FirstAdjustmentMonth = 30,
CashToClose = 31,
ULDDCalculator = 32,
BLTV = 33,
AmortizationPaymentsBestCase = 34,
AmortizationPaymentsAtMarket = 35,
CocTracking = 36,
VaNtbCashOutCalculator = 37,
AppraisalProductName = 38,
}

export enum CalculatingCashToCloseTypeEnum {
LeStandard = 0,
LeAlternate = 1,
ClosingDisclosures = 2,
}

export enum CalculationTypeEnum {
None = 0,
Tax = 1,
HOI = 2,
}

export enum CertificationIdEnum {
SelectOne = 0,
ValidStateDriverLicense = 1,
ValidStateIdentificationCard = 2,
MilitaryIdentificationCard = 3,
MilitaryDependentsPhotoID = 4,
USPassport = 5,
NonUSPassport = 6,
ResidentAlienCard = 7,
DepartmentOfPublicWelfarePhotoID = 8,
StudentPhotoID = 9,
WorkIDWithPhoto = 10,
MedicareCard = 11,
CustomerIDValidationCheck = 12,
ClosingCertification = 13,
Other = 14,
}

export enum CitizenResidencyTypeEnum {
SelectOne = -1,
PermanentResidentAlien = 0,
USCitizen = 1,
NonPermanentResidentAlien = 2,
}

export enum ClassificationStatusEnum {
DO_NOT_CLASSIFY = 1,
CLASSIFICATION_REQUESTED = 2,
CLASSIFICATION_APPROVED = 3,
CLASSIFICATION_DENIED = 4,
CLASSIFICATION_IN_PROGRESS = 5,
CLASSIFICATION_COMPLETED = 6,
AUTO_CLASSIFY_COMPLETED = 7,
}

export enum ClearsConditionTypeEnum {
CollateralAnalyst = 1,
SettlementServices = 2,
ConsumerEvaluation = 3,
ConsumerLiasion = 4,
ComplianceVendorServices = 5,
}

export enum CocDescriptionEnum {
LoanAmountChange = 1,
AppraisalRelatedChange = 2,
LoanProgramChange = 3,
LoanPurposeChange = 4,
InspectionRequired = 5,
SubjectPropertyTypeChange = 6,
CreditRelatedChange = 7,
ImpoundChange = 8,
BorrowerAdded = 9,
SubjectPropertyValueChanged = 10,
RateLock = 11,
OccupancyChange = 12,
CLTVChange = 13,
MortgageInsuranceChange = 14,
SalesPriceChange = 15,
RateLockExtension = 16,
PreviouslyUnknownInformationHasBeenProvidedByTheAppraisalReport = 17,
UnderwriterConditionRequiresAdditionalCharges = 18,
QuitClaimAdded = 19,
UnknownSubordinationAdded = 20,
NewlyReceivedInformation = 21,
BorrowerRequested = 22,
Other = 23,
}

export enum CocFieldTypeEnum {
None = 0,
PropertyType = 1,
OccupancyType = 2,
LoanAmount = 3,
LoanType = 4,
LoanProgram = 5,
LoanPurpose = 6,
PurchasePrice = 7,
TotalIncome = 8,
NumberOfUnits = 9,
Term = 10,
EstimatedValue = 11,
AppraisedValue = 12,
ManualHARPIndicator = 13,
CLTV = 14,
CreditScore = 15,
LockIndicator = 16,
Cost = 17,
Structure = 18,
TotalLoanAmount = 19,
UFMIPFundingFee = 20,
Rate = 21,
HomeLoanPortal = 22,
WorkflowType = 23,
IsNonWarrantableCondo = 24,
AppraisalProductName = 25,
}

export enum CocReasonStatusEnum {
None = 0,
ValidReason = 1,
InvalidReason = 2,
}

export enum CocReasonTypeEnum {
Other = 0,
Eligibility = 1,
Settlement = 2,
BorrowerRequested = 3,
Delayed = 4,
ExpiredLE = 5,
Locked = 6,
}

export enum CommunityLendingProductTypeEnum {
None = 0,
HFAPreferredRiskSharing = 1,
HFAPreferred = 2,
HomeReady = 3,
}

export enum CommunityLendingRepaymentStructureEnum {
None = 0,
AnyPaymentWithinFirst5Years = 1,
PaymentsDeferred5PlusYearsAndFullyForgiven = 2,
PaymentsDeferred5PlusYearsAndNotFullyForgiven = 3,
}

export enum CompanyHierarchyItemTypeEnum {
Unknown = 0,
Company = 1,
Channel = 2,
Division = 3,
Branch = 4,
LoanOfficer = 5,
}

export enum CompareExpression {
Equals = 1,
NotEquals = 2,
}

export enum ComplianceCheckStatusEnum {
NotRun = 0,
Passed = 1,
Failed = 2,
InProgress = 3,
}

export enum ComplianceResultDetailTypeEnum {
ReportedDataError = 1,
ReportedSystemError = 2,
}

export enum ConcessionApproverEnum {
Rate = 0,
Price = 1,
}

export enum Concurrent2ndMortgageEnum {
No = 0,
Fixed = 1,
HELOC = 2,
Community = 3,
}

export enum ConditionDueTypeEnum {
PriorToApproval = 1,
PriorToFunding = 2,
PriorToDocument = 3,
PriorToPostClosing = 4,
}

export enum ConditionGroupCategoryTypeEnum {
Property = 1,
Escrow = 2,
Assets = 3,
Credit = 4,
Identity = 5,
Income = 6,
Insurance = 7,
Compliance = 8,
}

export enum ConditionItemStatusTypeEnum {
Needed = 1,
Requested = 2,
Ordered = 3,
Received = 4,
ReadyToReview = 5,
PastDue = 6,
Cleared = 7,
Waived = 8,
}

export enum ConditionSourceTypeEnum {
Manual = 1,
}

export enum ConditionTypeEnum {
Loan = 1,
Borrower = 2,
}

export enum ConfigurationSettingKeyEnum {
SecureUserAccountServiceEnabled = 1,
PasswordPolicyHTMLDescription = 2,
PasswordLowerCaseCount = 3,
PasswordMinLength = 4,
PasswordNumbersCount = 5,
PasswordSymbolsCount = 6,
PasswordUpperCaseCount = 7,
UserAccountExternalAuthorizationEnabled = 8,
ClientDashboardUrl = 9,
ClientMyLoansUrl = 10,
}

export enum ConsentStatusEnum {
None = 0,
Accept = 1,
Decline = 2,
}

export enum ConstructionToPermClosingTypeEnum {
OneClosing = 1,
TwoClosing = 2,
}

export enum ConstructionTypeEnum {
ConstructionToPermanent = 1,
}

export enum CostContainer {
LoanCosts = 1,
OtherCosts = 2,
LenderCredits = 3,
CreditCosts = 4,
}

export enum CostPaidByTypeEnum {
Borrower = 0,
Broker = 1,
Lender = 2,
Other = 3,
EscrowCompany = 4,
LenderRebate = 5,
LenderBranch = 6,
LenderCorporate = 7,
LenderDivision = 8,
LenderLoanOfficer = 9,
Seller = 10,
}

export enum CostPaidToTypeEnum {
Borrower = 1,
Seller = 2,
Lender = 3,
Other = 10,
}

export enum CostSectionTypeEnum {
OriginationCharges = 1,
ServicesBorrowerDidNotShopFor = 2,
ServicesBorrowerDidShopFor = 3,
TaxesAndOtherGovermentFees = 4,
Prepaids = 5,
InitialEscowPaymentAtClosing = 6,
Other = 7,
LenderCredits = 8,
}

export enum CostTypeEnum {
EstimatedLenderCosts = 0,
EstimatedThirdPartyCosts = 1,
Prepaids = 2,
EstimatedReservesDepositedwithLender = 3,
}

export enum CounselingFormatTypeEnum {
    None = 0,
    FaceToFace = 1,
    Internet = 2,
    Telephone = 3,
    Hybrid = 4,
}

export enum CounselingProviderTypeEnum {
    None = 0,
    HudApprovedAgency = 1,
    NotHudApprovedAgency = 2
}

export enum CounselingTypeEnum {
    None = 0,
    Education = 1,
    Housing = 2
}

export enum CounselingWorkshopTypeEnum {
    None = 0,
    InPerson = 1,
    WebBased = 2,
}

export enum CreditCardTypeEnum {
None = 0,
MasterCard = 1,
Visa = 2,
VisaElectron = 3,
Discover = 4,
AmericanExpress = 5,
}

export enum CreditReportRequestTypeEnum {
Single = 1,
TriMerged = 3,
}

export enum CreditReportStatusTypeEnum {
NotInitiated = 0,
Retrieving = 1,
Retrieved = 2,
Error = 3,
}

export enum CreditRepositorySourceTypeEnum {
Other = 0,
Equifax = 1,
Experian = 2,
TransUnion = 3,
MergedData = 4,
}

export enum CreditRequestTypeEnum {
Submit = 1,
Reissue = 2,
Upgrade = 3,
}

export enum CreditRunButtonMenuEnum {
RunNewCreditReport = 1,
ReissueCreditReport = 2,
RemoveBorrower = 3,
RemoveCoborrower = 4,
}

export enum CreditScoreSourceEnum {
Equifax = 1,
TransUnion = 2,
Experian = 3,
}

export enum CreditTriggeredByEnum {
NotTriggered = 0,
Borrower = 1,
LoanOfficer = 2,
}

export enum CrmVelocifySettingEnum {
DefaultExportToLOS = 0,
CreditRan = 1,
ApplicationDate = 2,
InitialDisclosures = 3,
AUSRan = 4,
LoanDisclosuresMailed = 5,
LoanIsDisclosedInLOS = 6,
LoanIsCancelled = 7,
}

export enum CSharpTypeEnum {
Int = 1,
Decimal = 2,
String = 3,
Datetime = 4,
List = 5,
Currency = 6,
Percentage = 7,
}

export enum DataType {
None = 0,
String = 1,
Integer = 2,
Decimal = 3,
DateTime = 4,
Bit = 5,
}

export enum DebtCommentTypeEnum {
Agree = 0,
Disagree = 1,
PaidOff = 3,
PayoffAtClose = 4,
AccountNotMine = 5,
Duplicate = 6,
NotMyLoan = 7,
SomeoneElsePays = 8,
NonBorrowingSpouse = 9,
}

export enum DebtsAccountOwnershipTypeEnum {
Borrower = 0,
CoBorrower = 1,
Joint = 2,
BorrowerWithOther = 3,
CoBorrowerWithOther = 4,
}

export enum DecisionLevelEnum {
First = 1,
Second = 2,
}

export enum DeductionTypeEnum {
Federal = 1,
State = 2,
RetirementOrSocialSecurity = 3,
Other = 4,
}

export enum DefaultDataTypeEnum {
None = 0,
LoanData = 1,
PriceData = 2,
FeesData = 3,
LoanProductData = 4,
PriceCustomFieldData = 5,
}

export enum DefaultMIRateTypeEnum {
BaseLoanAmount = 0,
Constant = 1,
Declining = 2,
}

export enum DisclosureDocumentRecipientTypeEnum {
IndividualBorrower = 0,
AllBorrowers = 1,
}

export enum DisclosureMessageTypeEnum {
ERROR = 1,
WARNING = 2,
}

export enum DisclosuresActionType {
None = 0,
PrintAndShip = 1,
ESign = 2,
ComplianceCheck = 3,
PrintToPdf = 4,
InitialDocument = 5,
PreviewDisclosures = 6,
InitialDisclosureInPerson = 7,
AdverseDocument = 8,
SelectedDisclosures = 9,
DocumentList = 10,
}

export enum DisclosureSelectedDocsRequestStatus {
None = 0,
Requested = 1,
Success = 2,
Failed = 3,
}

export enum DisclosureSettingsEnum {
None = 0,
AllBorrowersRequiredForEConsent = 1,
ReviewRevisedDisclosures = 2,
RedisclosureRequiredSignature = 3,
ReviewRevisedDisclosuresESign = 4,
TestModeLoanProduct = 5,
PrintAndShipOfflineDisclosures = 6,
AutoSplitInitialDisclosurePackage = 7,
DisplaySurveyFeeAsNewProviderType = 8,
EncompassRedisclosures = 9,
DisableInitialDisclosureAfterDueDate = 10,
StandardMethodCalculatingCashToCloseRefi = 11,
AddQMTestToComplianceCheck = 12,
ImpReDisclosures = 13,
IWorkflowApproveDisclosures = 14,
VendorPrintAndShipNotification = 15,
ComplianceCheckRequired = 16,
RunComplianckCheckForAutoDisclosures = 17,
DocDeliveryType = 18,
AutoSendSecureLink = 19,
AutoAssignDefaultLo = 20,
AutoAssignProductAndFees = 21,
LoanNumberRequiredForDiscl = 22,
ShowComplianceCheckPopup = 23,
ExportToLosRequiredForDiscl = 24,
DisplayLOSignatureInDisclosurePackage = 25,
TRIDIndicator = 26,
CashToCloseIndicator = 27,
EnableBrokerDisclosures = 28,
}

export enum DisclosureSettingTypeEnum {
Disclosure = 0,
AutoDisclosure = 1,
General = 2,
}

export enum DisclosureStatusEnum {
NotNeeded = 1,
InitialDisclosureRequired = 2,
ReDisclosureRequired = 3,
RequestInProgress = 4,
DisclosuresCreated = 5,
DisclosuresFailed = 6,
WaitingToBeSigned = 7,
DisclosuresSigned = 8,
Mailed = 9,
SignedOffline = 10,
ExitWorkflow = 11,
ReDisclosuresInProgress = 12,
ReDisclosuresCreated = 13,
ReDisclosuresWaitingToBeSigned = 14,
ReDisclosuresSigned = 15,
ReDisclosuresMailed = 16,
ReDisclosuresSignedOffline = 17,
ReDisclosuresFailed = 18,
AutoEFailed = 19,
AutoECreated = 20,
WaitingForExitWorkflow = 21,
}

export enum DisplayModeEnum {
None = 1,
BorrowerView = 2,
LoanOfficerView = 4,
}

export enum DocDeliveryTypeEnum {
Mail = 0,
Electronic = 1,
}

export enum DocumentAcceptanceActionEnum {
View = 1,
Preview = 2,
Sign = 3,
}

export enum DocumentAcceptanceStatusEnum {
NotRequired = 0,
ViewRequired = 1,
Viewed = 2,
SigRequired = 3,
SigPending = 4,
SigPartial = 5,
Signed = 6,
SigDeclined = 7,
ViewPending = 8,
}

export enum DocumentClassEnum {
None = 0,
IncomeVerification = 1,
LoanDisclosuresPackage = 2,
AppraisalReport = 3,
CreditReport = 4,
Authorization = 5,
AuthorizationsPackage = 6,
OtherDocuments = 7,
LoanReDisclosuresPackage = 8,
MiscellaneousDocuments = 9,
SmartGFEComplianceCertificate = 10,
PreApprovalLetter = 11,
Collateral = 12,
VariousDocuments = 13,
AppraisalDocuments = 14,
CounterOfferApprovalLetter = 15,
CounterOfferApprovalLetterInitialDisclousure = 16,
InitialDisclosuresMailingCoverLetter = 17,
ReDisclosuresMailingCoverLetter = 18,
LoanDisclosuresDocuments = 20,
BorrowersNeedsListToBeCompleted = 21,
AssetsDocuments = 22,
ComplianceCheck = 23,
WetSignaturesReDisclosures = 24,
LoanReDisclosuresPackageCD = 25,
eMiscellaneousDoc = 26,
MiscellaneousWetSign = 27,
Internal = 80,
Other = 99,
}

export enum DocumentContentType {
Unknown = 0,
PDF = 1,
DOC = 2,
DOCX = 3,
XLS = 4,
XLSX = 5,
JPG = 6,
XML = 7,
ZIP = 8,
TXT = 9,
PNG = 10,
GIF = 11,
HTML = 12,
TIF = 13,
RAR = 14,
RTF = 15,
FNM = 16,
JPEG = 17,
CSV = 18,
TIFF = 19,
}

export enum DocumentDeliveryMethodEnum {
Electronic = 1,
Mail = 2,
Fax = 3,
}

export enum DocumentIntentsEnum {
NotSpecified = 0,
Information = 1,
View = 2,
Sign = 6,
}

export enum DocumentMediaTypeEnum {
DEFAULT = 0,
DOC = 1,
DOCX = 2,
GIF = 3,
JPG = 4,
PDF = 5,
PNG = 6,
TXT = 7,
XLS = 8,
XLSX = 9,
}

export enum DocumentRequestOptionEnum {
Content = 0,
Metadata = 1,
None = 2,
}

export enum DocumentStateEnum {
Pending = 0,
Approved = 1,
Rejected = 2,
Deleted = -1,
}

export enum DocumentStatusEnum {
None = 0,
Active = 1,
Removed = 2,
Approved = 3,
Rejected = 4,
}

export enum DomesticRelationshipRightsEnum {
NoNoOneElseHasPropertyRights = 0,
YesThereIsARelationshipWithSomeoneWhoHasPropertyRights = 1,
SelectOne = -1,
}

export enum DownPaymentTypeSourceEnum {
Checking_Savings = 1,
DepositonSalesContract = 2,
EquitySoldonProperty = 3,
EquityPendingfromSale = 4,
EquityPendingfromSubjectProperty = 5,
GiftFunds = 6,
Stocksandbonds = 7,
LotEquity = 8,
BridgeLoan = 9,
UnsecuredBorrowedFunds = 10,
TrustFunds = 11,
RetirementFunds = 12,
Rentwithoptiontopurchase = 13,
Lifeinsurancecashvalue = 14,
SaleofChattel = 15,
TradeEquity = 16,
SweatEquity = 17,
Other = 19,
SecuredBorrowedFunds = 20,
FHA_Gift_SourceN_A = 21,
FHA_Gift_SourceRelative = 22,
FHA_Gift_SourceGovernmentAssistance = 23,
FHA_Gift_SourceEmployer = 24,
FHA_Gift_SourceNonprofit_Religious_Community_SellerFunded = 25,
FHA_Gift_SourceNonprofit_Religious_Community_Non_SellerFunded = 26,
CashonHand = 27,
}

export enum DoYouPayMortgageInsuranceEnum {
No = 0,
YesMonthly = 1,
YesAnnual = 2,
YesOneLumpSum = 3,
YesSingleSpecific = 4,
}

export enum ElectronicAddressTypeEnum {
HomePhone = 0,
CellPhone = 1,
WorkPhone = 2,
OtherPhone = 3,
Email = 4,
WorkEmail = 5,
Fax = 6,
Unspecified = -1,
}

export enum EmailTemplateTypeEnum {
SecureLink = 1,
PasswordReset = 2,
}

export enum EmploymentStatusTypeEnum {
Current = 1,
Previous = 2,
}

export enum EmploymentTypeEnum {
ActiveMilitaryDuty = 0,
SalariedEmployee = 1,
SelfEmployed = 2,
Retired = 3,
OtherOrUnemployed = 4,
OtherIncome = 99,
None = -1,
}

export enum EmploymentVerificationProviderTypeEnum {
Equifax = 0,
FannieMae = 1,
FreddieMac = 2,
}

export enum EmploymentVerificationTypeEnum {
None = 0,
eVOI = 1,
eVOE = 2,
}

export enum EmptyDataPriorityEnum {
EmptyFirst = 1,
EmptyLast = 2,
}

export enum EncompassPrintNShipFieldNameEnum {
BorrowerPaperOut = 1,
BorrowerSentDate = 2,
CoBorrowerPaperOut = 3,
CoBorrowerSentDate = 4,
}

export enum EncompassRecordFieldNameEnum {
Status = 1,
StatusDate = 2,
IPAddress = 3,
Source = 4,
CoBorrowerStatus = 5,
CoBorrowerStatusDate = 6,
CoBorrowerIPAddress = 7,
CoBorrowerSource = 8,
}

export enum EncompassRecordNameEnum {
eConsentRecord = 1,
IntentToProceed = 2,
PrintNShip = 3,
ReDisclosures = 4,
}

export enum EncompassRedisclosuresFieldNameEnum {
LoanApplicationId = 1,
}

export enum EntitlementUsedTypeEnum {
PrimaryBorrowerVeteran = 1,
PrimaryBorrowerSurvivingSpouse = 2,
DeceasedSpouse = 3,
CoBorrower = 4,
NoneOfTheAbove = 5,
}

export enum EntityTypeEnum {
Loan = 0,
Borrower = 1,
Document = 2,
Property = 3,
UserAccount = 4,
BorrowerNeed = 5,
}

export enum ErrorTypeEnum {
Generic = 0,
Pricing = 1,
Fees = 2,
LocationBasedFees = 3,
RecordingOffices = 4,
Security = 5,
ProgramFeature = 6,
ComplianceChecklist = 7,
}

export enum ESignerRoleEnum {
Borrower = 1,
CoBorrower = 2,
}

export enum EstateType {
None = 0,
FeeSimple = 1,
LEasehold = 2,
LifeEstate = 3,
}

export enum ExistingSecondMortgageTypeEnum {
NoExistingMortgage = 0,
Fixed = 1,
HELOC = 2,
}

export enum ExportDocumentToEncompassStatusEnum {
Unknown = 0,
Running = 1,
Success = 2,
Failed = 3,
Queued = 4,
}

export enum ExportToLOSAfterCreditSuccess {
Disabled = 0,
ExportAndDiscloseInLOS = 1,
ExportToLOS = 2,
}

export enum FeeCategoryEnum {
None = 0,
Escrow = 1,
Title = 2,
PestInspection = 3,
Survey = 4,
HomeWarranty = 5,
}

export enum FeeProviderEnum {
DefaultTable = 0,
ClosingCorp = 1,
MicroService = 2,
}

export enum FeeRequestTypeEnum {
Estimate = 0,
Actual = 1,
}

export enum FeeTypeEnum {
AppraisalRelated = 1,
AppraisalRushFee = 2,
LoanPrice = 3,
BrokerCompensationFee = 4,
VAFundingFee = 5,
RuralDevelopmentGuaranteeFee = 6,
UpfrontMIPremium = 7,
TitleEscrow_ClosingFeeAndLendersTitleInsurance = 8,
TitleEndorsementFee = 9,
TitleLandSurveyFee = 10,
TitleSubordinationFee = 11,
LenderSubordinationFee = 12,
TaxesRelatedFees = 13,
HOAQuestionnaireFee = 14,
OccupancyInspectionFee = 15,
PestInspectionFee = 16,
BorrowerPaidMISinglePremium = 17,
Recordings = 18,
}

export enum FHAProductsEnum {
None = 0,
StreamlineRefinance = 1,
RateAndTermRefinance = 2,
SimpleRefinance = 3,
CashOutRefinance = 4,
Purchase = 5,
PurchaseDown = 6,
}

export enum FHAPropertyOccupiedForEnum {
LessThanOneYear = 1,
MoreThanOneYear = 2,
}

export enum FicoScoreTypeEnum {
Amazing = 0,
Exceptional = 1,
Fantastic = 2,
Superb = 3,
Excellent = 4,
Outstanding = 5,
Great = 6,
VeryGood = 7,
Good = 8,
Average = 9,
BelowAverage = 10,
Poor = 11,
NonTraditionalCredit = 12,
}

export enum FieldsForValidationOnSaveEnum {
BorrowerFirstNameAndLastName = 0,
CoborrowerFirstNameAndLastName = 1,
BorrowerFirstNameAndLastNameAndSSN = 2,
CoborrowerFirstNameAndLastNameAndSSN = 3,
NumberOfFields = 4,
AllFieldsValid = -1,
}

export enum FileUploadedByEnum {
None = 0,
Consumer = 1,
System = 2,
Concierge = 3,
}

export enum FileUploadedFromEnum {
None = 0,
BorrowerNeed = 1,
DocVault = 2,
}

export enum FinancedTypeEnum {
None = 1,
All = 2,
Partial = 3,
}

export enum Form4506TypeEnum {
Individual = 0,
Business = 1,
}

export enum FormatInstruction {
Custom = 0,
Text = 1,
Number = 2,
Currency = 3,
DateTime = 4,
Date = 5,
ThreeDecimalAllowZero = 6,
ThreeDecimal = 7,
YesNo = 8,
HrsUntilAutoDisclosure = 9,
AutoDiscloseCleared = 10,
DispositionButton = 11,
DispositionUserTime = 12,
GovtEligibility = 14,
}

export enum FormNameEnum {
Default = 0,
TransactionSummaryViewModel = 1,
}

export enum GeneralSettingsEnum {
AutoAssignConcierge = 1,
FraudDetection = 2,
AutocompleteLoanApplication = 3,
ShowPurchaseLoanOption = 4,
ShowInvestmentProperty = 5,
ShowSecondVacationHome = 6,
DisplayContactInformationUnderPersonalSection = 7,
DeliveryMethodEnabled = 8,
DoNotShowBorrowerSiteLogin = 9,
FeedbackWindow = 10,
EnableSmartCreditsForBorrowerDirectModel = 11,
EnableSmartCreditsForLoOrConciergeModel = 12,
RequiredFields = 13,
DisplayContactInformationUnderPersonalSectionForLoOrConciergeModel = 14,
DeliveryMethodEnabledforLoOrConciergeModel = 15,
AutoExportCreditReporttoEncompass = 16,
ShowInitialLeadsinProspectQueue = 17,
CustomRoletoDisplayonConsumersite = 18,
RequiredfieldsforHowDidYouHearAboutUs = 19,
RequiredfieldsforLeads360ID = 20,
HARP20 = 21,
QuickInterviewStart = 22,
CDNEnabledForBorrower = 23,
ShowAllMyOptionsasDefaultonShoppingCartBorrower = 24,
ReviewDisclosuresHideExitMessage = 25,
LoanCenterShowStartProspectbutton = 26,
EnableLicensing = 27,
EnableNewAppraisalFeeConfiguration = 28,
DisableMarketingMessageSaveupto_500 = 29,
ShowManualUpdateButton = 30,
ExportnetrentaltoEncompassSDK = 31,
CDNEnabledForConcierge = 32,
ItemizeLenderCredit = 33,
ExportGFERateLockPeriodpriortoRateLock = 34,
EnableTimeLogging = 35,
CDNEnabledForLoanCenter = 36,
EnableSeparateGoogleAnalyticsforRED = 37,
EnableSPHLGoogleAnalyticsforRED = 38,
ShowBestRatesNotAvailable = 39,
EnableRedIntegration = 40,
DonotshowConciergeSiteLogin = 41,
UseTestPasswordforRED = 42,
ShowAllMyOptionsasDefaultonShoppingCartConcierge = 43,
CDNEnabledforAffinity = 44,
CDNEnabledforCorporate = 45,
CDNEnabledforDirect = 46,
CDNEnabledforWholesale = 47,
CDNEnabledforSkyline = 48,
VacancyFactor = 49,
CDNEnabledforAffordability = 50,
MultiBorrowerEnableAdditional1003Applications = 51,
EnableAppraisalAppointmentNotifications = 52,
EnableNewCreditIntegrationConfiguration = 53,
EnableNewAUSIntegrationConfiguration = 54,
EnableExportDocumentToEncompass = 55,
MultiBorrowerMaximalnumberof1003applicationsperloan = 56,
Before10_03_2015 = 57,
ComplianceCheckRequiredtoCreateInitialDisclosures = 58,
AutoExporttoEncompass = 59,
ShowPreApprovalLetterMegaMenu = 60,
DeclarationsForCoBorrowerDefaulted = 62,
LoanCenterDefaultSubjectPropertyFields = 63,
RequireShopForRatesBeforeInitialDisclosures = 64,
EnablePricingButtonAfterITP = 65,
AutoAdvanceAppCompletedLoansToProcessingList = 66,
Enable6PiecestoRunCreditInWA = 67,
EnableFaxCoverSheet = 68,
VAEntitlementAmount = 69,
DefaultMIRateType = 70,
LOAccountRequiredOnLeadImport = 71,
CalculateAPRUsingOddDays = 72,
USDAIncomeLimitURL = 73,
DisplayUSDAUploadSection = 77,
UploadDocumentsSupportedFileTypes = 97,
UploadDocumentsMaximumFileSize = 98,
LoanCenterDemographicsDisableIDonNotWishCheckbox = 178,
USDAPropertyEligibilityURL = 179,
LockLoanOnOpen = 180,
LoanLockDuration = 181,
UploadDocumentsAtConsumerSiteSupportedFileTypes = 182,
DocumentTypeIncludedInAutoClassificationWorkflow = 183,
}

export enum GFETypeEnum {
WhatIf = 0,
SmartGFE = 1,
}

export enum GiftandgrantsrelationshipEnum {
Parent = 1,
Child = 2,
Sibling = 3,
Grandparents = 4,
Fiance = 5,
Spouse = 6,
Employer = 7,
NonProfit = 8,
CommunityNonprofit = 9,
FederalAgency = 10,
LocalAgency = 11,
Relative = 12,
ReligiousNonprofit = 13,
StateAgency = 14,
UnmarriedPartner = 15,
Other = 16,
Unknown = -1,
Lender = 17,
}

export enum GlobalCompanySearchTypeEnum {
CompanyName = 0,
Phone = 1,
ContactName = 2,
}

export enum GovermentEligibilityEnum {
None = 0,
FHAEligible = 1,
FHANotEligible = 2,
VAEligible = 3,
VANotEligible = 4,
USDAEligible = 5,
USDANotEligible = 6,
}

export enum HarpLoanType {
None = 0,
FannieMae = 1,
FreddieMac = 2,
}

export enum HMDAEthnicityTypeEnum {
None = 0,
HispanicOrLatino = 1,
Mexican = 2,
PuertoRican = 4,
Cuban = 8,
OtherHispanicOrLatino = 16,
NotHispanicOrLatino = 32,
IDoNotWishToProvideThisInformation = 64,
}

export enum HMDARaceTypeEnum {
None = 0,
AmericanIndianOrAlaskaNative = 1,
Asian = 2,
AsianIndian = 4,
Chinese = 8,
Filipino = 16,
Japanese = 32,
Korean = 64,
Vietnamese = 128,
OtherAsian = 256,
BlackOrAfricanAmerican = 512,
NativeHawaiianOrOtherPacificIslander = 1024,
NativeHawaiian = 2048,
GuamanianorChamorro = 4096,
Samoan = 8192,
OtherPacificIslander = 16384,
White = 32768,
IDoNotWishToProvideThisInformation = 65536,
}

export enum HMDASexTypeEnum {
None = 0,
Male = 1,
Female = 2,
IDoNotWishToProvideThisInformation = 4,
}

export enum HolidayTypeEnum {
None = 0,
National = 1,
State = 2,
}

export enum HomeBuyingTypeEnum {
None = 0,
OfferAccepted = 1,
OfferPendingFoundAHouse = 2,
GetPreApproved = 3,
WhatCanIAfford = 4,
}

export enum HopeLoanPortalEnum {
NA = 0,
ReceivedFromHLP = 1,
ReferredToHLP = 2,
}

export enum HouseholdMemberTypeEnum {
None = 0,
Borrower = 1,
CoBorrower = 2,
Household = 3,
}

export enum HouseholdTitleTypeEnum {
None = 0,
Borrower = 1,
CoBorrower = 2,
AdditionalIncomeToPrimaryIncome = 3,
IncomeForAdditionalAdultHouseholdMembers = 4,
IncomeFromAssets = 5,
DependentDeduction = 6,
AnnualChildCareExpenses = 7,
ElderlyHousehold = 8,
AnnualDisabilityExpenses = 9,
AnnualMedicalExpenses = 10,
}

export enum HouseholdTypeEnum {
None = 0,
Income = 1,
Deduction = 2,
}

export enum ImpSystemModeEnum {
None = 0,
Epos = 1,
Cpos = 2,
DigApp = 3,
SpecialWorkflow = 4,
}

export enum IncomeTypeEnum {
BaseEmployment = 0,
Overtime = 1,
Bonuses = 2,
Commissions = 3,
DividendsInterest = 4,
NetRental = 5,
Other = 6,
TrustIncome = 7,
AlimonyChildSupport = 8,
AutomobileExpenseAccount = 9,
FosterCare = 10,
MilitaryBasePay = 11,
NotesReceivableInstallment = 12,
PartTime = 13,
SocialSecurity = 14,
DisabilityIncome = 15,
SelfEmployedIncome = 16,
RetirementPensionIncome = 17,
MilitaryClothesAllows = 18,
MilitaryCombatPay = 19,
MilitaryFlightPay = 20,
MilitaryHazardPay = 21,
MilitaryOverseasPay = 22,
MilitaryPropPay = 23,
MilitaryQuartersAllowance = 24,
MilitaryRationsAllowance = 25,
MilitaryVariableHousingAllowance = 26,
Unemployment = 27,
VABenefitsNonEducational = 28,
BoarderIncome = 29,
CapitalGains = 30,
EmploymentRelatedAssets = 31,
ForeignIncome = 32,
MortgageCreditCertificate = 33,
RealEstateMortgageDifferential = 34,
RoyaltyPayment = 35,
SeasonalIncome = 36,
TemporaryLeave = 37,
TipIncome = 38,
MiscBusinessIncome = 39,
ChildSupport = 40,
HousingOrParsonage = 41,
PublicAssistance = 42,
SeparateMaintenance = 43,
}

export enum IncomeTypeNetRentalEnum {
AdjustedNetRentalIncome = 1,
NetRentalIncome = 2,
AnticipatedNetRentalIncome = 3,
AdjustedAnticipatedNetRentalIncome = 4,
}

export enum IncomeVerificationPayFrequencyTypeEnum {
None = 0,
Annual = 1,
SemiAnnual = 2,
Monthly = 4,
SemiMonthly = 5,
BiWeekly = 6,
Weekly = 7,
Daily = 8,
Hourly = 9,
ThirteenTimesPerYear = 10,
CommissionOnly = 11,
TenTimesPerYear = 12,
GuaranteedIncome = 13,
ElevenTimesPerYear = 14,
OwnerBasePay = 15,
HourlyWithoutCommission = 16,
HourlyPlusCommission = 17,
Quarterly = 18,
MonthlyPension = 19,
Monthly2xJanNoPayDec = 20,
HourlyOrCommission = 21,
PerTrip = 22,
PerTask = 23,
MonthlyAcademic9Month = 24,
MonthlyAcademic10Month = 25,
MonthlyAcademicPerSemester = 26,
MonthlyAcademic12Month = 27,
AnnualAcademic9Month = 28,
AnnualAcademic10Month = 29,
AnnualAcademic12Month = 30,
AnnualAcademic11Month = 31,
}

export enum IncomeVerificationPayPeriodTypeEnum {
None = 0,
Annual = 1,
SemiAnnual = 2,
Monthly = 4,
SemiMonthly = 5,
BiWeekly = 6,
Weekly = 7,
Daily = 8,
}

export enum IncomeVerificationVendorEnum {
CoreLogic = 10,
EquiFax = 15,
}

export enum IndianCountryLandTenureTypeEnum {
FeeSimple = 1,
IndividualTrustLand = 2,
Other = 4,
AlaskaNativeCorporationLand = 8,
TribalTrustLandOffReservation = 16,
TribalTrustLandOnAReservation = 32,
}

export enum IntegrationLookupSystemEnum {
Encompass = 1,
}

export enum IntegrationMessageTypeEnum {
RegistrationLenderTpoWarningMessage = 0,
RegistrationLenderErrorMessage = 1,
}

export enum IntegrationStatusDetailStateTypeEnum {
None = 0,
Completed = 1,
Errored = 2,
}

export enum IntegrationStatusDetailTypeEnum {
IntegrationRequestData = 1,
IntegrationResponseData = 2,
TransformationSourceData = 3,
VendorRequestData = 4,
VendorResponseData = 5,
SystemError = 6,
Cancelled = 7,
IntermediateResponse = 8,
ProviderData = 9,
Errors = 10,
VendorAsyncResponse = 11,
}

export enum IntegrationStatusTypeEnum {
None = 0,
New = 1,
Processing = 2,
Completed = 3,
Errored = 4,
Abandoned = 5,
WaitingForResponse = 6,
ProcessingResponse = 7,
}

export enum IntegrationTokenTypeEnum {
None = 0,
OBAuthorizationToken = 1,
Arive = 2,
SunWest = 3,
}

export enum IntegrationVendorProductEntitlementSettingEnum {
None = 0,
ProcessFeesPerProduct = 1,
TurnOffFeeLoanCalculator = 2,
RequestRecordingOffices = 3,
LockLoanAfterComplianceCheck = 4,
DisableFeeCalc = 5,
RequestTaxQuestions = 6,
ComplianceCheck = 7,
DocumentGeneration = 14,
SaveAndExportToLOSOnApplyUpdateProduct = 15,
CheckProductEligibilityOnApplyUpdateProduct = 16,
SecurityTokenRequired = 20,
ErrorCallbackAfterRepricing = 21,
DisableDisclButtonAfterSigning = 22,
}

export enum IntegrationVendorTaxSettingSettingTypeEnum {
Individual = 1,
Business = 2,
}

export enum InvestorTypeEnum {
None = 0,
Banked = 1,
Brokered = 2,
Wholesale = 3,
}

export enum ItemizationDescriptionTypesEnum {
Installment1 = 1,
Installment2 = 2,
Installment3 = 3,
Installment4 = 4,
MelloRoos = 5,
Supplemental1 = 6,
Supplemental2 = 7,
Supplemental3 = 8,
}

export enum ItemSourceTypeEnum {
User = 0,
Credit = 1,
}

export enum LedgerEntryNameEnum {
Empty = 0,
BorrowerClosingCosts = 1,
BorrowerDeposit = 2,
ExistingLoansTaken = 3,
LoanAmount = 4,
SalePriceOfPersonalProperty = 5,
SalePriceOfProperty = 6,
SellerClosingCosts = 7,
SellerCredit = 8,
SellerExcessDeposit = 9,
CityTownTaxes = 10,
CountyTaxes = 11,
Assessments = 12,
HOADues = 13,
PayoffFirstMortgage = 14,
PayoffSecondMortgage = 15,
PayoffThirdMortgage = 16,
PayoffOtherMortgage = 17,
Other = 18,
BorrowerPaidFees = 19,
EmployerAsstHousing = 20,
LeasePurchaseFund = 21,
LenderCredit = 22,
RelocationFund = 23,
OtherDoubleEntry = 24,
Concurrent2ndMortgage = 25,
ClosingCostsForSubFinancing = 26,
}

export enum LedgerEntryTypeEnum {
NotSpecified = 0,
Credit = 1,
Debit = 2,
}

export enum LegalEntityTypeEnum {
NotSpecified = 0,
CPA = 1,
DocSigning = 2,
Escrow = 3,
FloodInsurance = 4,
HomeownerInsurance = 5,
PropertyManagement = 6,
HomeWarranty = 7,
Survey = 8,
PestInspection = 9,
MortgageInsurance = 10,
Realtor = 11,
TitleInsurance = 12,
Appraiser = 13,
Lender = 14,
AppraiserSupervisor = 15,
HomeownersAssociation = 16,
ReservedForFutureUse_3 = 22,
ReservedForFutureUse_2 = 23,
ReservedForFutureUse_1 = 24,
FutureUse = 31,
}

export enum LegalEntityTypeMask {
NotSpecified = 0,
CPA = 16,
DocSigning = 32,
Escrow = 64,
FloodInsurance = 128,
HomeownerInsurance = 256,
PropertyManagement = 512,
HomeWarranty = 1024,
Survey = 2048,
PestInspection = 4096,
MortgageInsurance = 8192,
Realtor = 16384,
TitleInsurance = 32768,
Appraiser = 65536,
AllLegalEntities = 1073741824,
}

export enum LenderStatusTypeEnum {
NotSpecified = 0,
Approved = 1,
}

export enum LiabilityTypeEnum {
Other = 0,
Revolving = 1,
Installment = 2,
Public = 3,
MovedFromReo = 4,
Alimony = 5,
ChildCare = 6,
ChildSupport = 7,
CollectionsJudgmentAndLiens = 8,
JobRelatedExpenses = 9,
LeasePayments = 10,
Open30DayChargeAccount = 11,
OtherLiability = 12,
SeparateMaintenanceExpense = 13,
}

export enum LienPositionTypeEnum {
First = 1,
Second = 2,
Third = 3,
Forth = 4,
Fifth = 5,
}

export enum LoanApplicationTypeEnum {
MainApplication = 1,
AdditionalApplication = 2,
}

export enum LoanChannelTypeEnum {
Banked = 0,
Brokered = 1,
Wholesale = 2,
}

export enum LoanContactType {
Other = 0,
LoanOfficer = 1,
Processor = 2,
LoanOfficerAssistant = 3,
}

export enum LoanDateTypeEnum {
AppraisalContingencyDate = 1,
ApprovalContingencyDate = 2,
ClosingDate = 3,
LenderApplicationDate = 4,
LoanInProcessDate = 5,
LoanSubmitted = 6,
LoanApproved = 7,
DocsRequested = 8,
DocsOut = 9,
LoanFunded = 10,
LoanCompleted = 11,
FirstPaymentDate = 12,
}

export enum LoanDecisionStatusTypeEnum {
PreApproved = 1,
Approved = 2,
Suspended = 3,
Declined = 4,
}

export enum LoanFieldDataTypeEnum {
String = 0,
Integer = 1,
Decimal = 2,
Percent = 3,
Date = 4,
}

export enum LoanFieldHistoryTypeEnum {
None = 0,
AppraisedValue = 1,
HowDidYouHearAboutUs = 2,
}

export enum LoanInfoRequestEnum {
NullRequest = 0,
LoanAmount = 1,
SalePriceOfProperty = 2,
BorrowerDeposit = 3,
BorrowerClosingCostsFromSectionJ = 4,
SellerClosingCostsFromSectionJ = 5,
}

export enum LoanLimitTypeEnum {
FHALoanLimit = 1,
VALoanLimit = 2,
}

export enum LoanLockControlStatusType {
Acquired = 1,
Released = 2,
TimedOut = 3,
}

export enum LoanOriginationInterviewTypeEnum {
FaceToFace = 0,
Telephone = 1,
FaxOrMail = 2,
EmailOrInternet = 3,
}

export enum LoanOriginationSourceEnum {
None = 0,
MML = 1,
Encompass = 2,
Freedom = 3,
}

export enum LoanPricingAdjustmentDropDownTypeEnum {
Adjustment = 0,
SubType = 1,
PaidBy = 2,
AssignedTo = 3,
}

export enum LoanPricingAdjustmentPaidByTypeEnum {
Unkonwn = 0,
LoanOfficer = 1,
Division = 2,
Company = 3,
Borrower = 4,
Channel = 5,
Branch = 6,
}

export enum LoanPricingAdjustmentTypeEnum {
None = 0,
Sec2Llpa = 1,
Sec2Concession = 2,
Sec2ExcessPricing = 3,
Sec2Extension = 4,
Sec2Renegotation = 5,
Sec2WorstCasePricing = 6,
Sec1CorporateMargin = 7,
Sec1DivisionMargin = 8,
Sec1LoanOfficerCompensation = 9,
Sec1ExcessPricing = 10,
Sec1Extension = 11,
Sec3ExcessPricing = 12,
Sec3Extension = 13,
Sec3Renegotation = 14,
Sec3WorstCasePricing = 15,
Sec1CompanyMargin = 16,
Sec1Compensation = 17,
Sec1Ipa = 18,
Sec1Ira = 19,
Sec2Llra = 20,
Sec2Ipa = 21,
Sec3Ipa = 22,
Sec2Llma = 23,
Sec2RateConcession = 24,
}

export enum LoanProductSettingTypeEnum {
None = 0,
SpecialProgram = 1,
SpecialFeature = 2,
}

export enum LoanProductSourceObjectTypeEnum {
Loan = 0,
Property = 1,
}

export enum LoanProductSpecialFeatureEnum {
None = 0,
MortgageCreditCertificate = 1,
MortgageCreditCertificateFHAIncome = 2,
MortgageCreditCertificateFHAOffset = 3,
MortgageCreditCertificateFHAIncomeOffset = 4,
FHA203KStandard = 5,
FHA203KLimited = 6,
EnergyEfficient = 7,
}

export enum LoanProductSpecialProgramEnum {
None = 0,
Texas50a6 = 1,
Texas50f2 = 2,
}

export enum LoanPurposeTypeEnum {
None = 0,
Purchase = 1,
Refinance = 2,
}

export enum LoanStatus {
Closed = 1,
InProgress = 2,
Cancelled = 3,
Reopened = 4,
}

export enum LoanSystemSourceEnum {
None = 0,
ConsumerSite = 1,
LoanCenter = 2,
}

export enum LoanTaxDataSourceEnum {
System = 0,
Manual = 1,
}

export enum LoanTaxDataTitleEnum {
None = 0,
Partner = 1,
Owner = 2,
ManagingMember = 3,
President = 4,
LimitedPartner = 5,
VicePresident = 6,
Secretary = 7,
Treasurer = 8,
Trustee = 9,
Beneficiary = 10,
SoleProprietor = 11,
}

export enum LockingActionTypeEnum {
None = 0,
SaveOnly = 1,
SubmitRegistrationRequest = 2,
SubmitLockRequest = 3,
SubmitChangeRequest = 4,
SubmitLockCancelation = 5,
CancelRequest = 6,
AcceptRegistrationRequest = 7,
AcceptLockRequest = 8,
DenyLock = 9,
AcceptChangeRequest = 10,
AcceptLockCancellation = 11,
TestSubmitLockRequest = 12,
OBPlugin = 13,
}

export enum LockingConfigurationEnum {
None = 0,
AllowedMinutesFromSearchToRequestMandatory = 1,
AllowedMinutesFromSearchToRequestEforts = 2,
AutoLockSettingsBestEfforts = 3,
AutoLockSettingsMandatoryEfforts = 4,
MaxNumberOfConcessions = 5,
MaxConcessionAmountTotal = 6,
MaxConcessionAmountLevel1 = 7,
MaxConcessionAmountLevel2 = 8,
MaxConcessionAmountLevel3 = 9,
MaxConcessionAmountLevel4 = 10,
MaxConcessionAmountLevel5 = 11,
LockCancellationRulesReason1 = 12,
LockCancellationRulesReason2 = 13,
LockCancellationRulesReason3 = 14,
LockCancellationRulesReason4 = 15,
LockCancellationRulesReason5 = 16,
LockCancellationRulesReason6 = 17,
MaxPriceConcessionAmountLevel6 = 18,
MaxPriceConcessionAmountLevel7 = 19,
MaxPriceConcessionAmountLevel8 = 20,
MaxPriceConcessionAmountLevel9 = 21,
MaxPriceConcessionAmountLevel10 = 22,
MaxNumberOfRateConcessions = 23,
MaxRateConcessionAmountTotal = 24,
MaxRateConcessionAmountLevel1 = 25,
MaxRateConcessionAmountLevel2 = 26,
MaxRateConcessionAmountLevel3 = 27,
MaxRateConcessionAmountLevel4 = 28,
MaxRateConcessionAmountLevel5 = 29,
ConcessionPaidByCompany = 30,
ConcessionPaidByChannel = 31,
ConcessionPaidByDivision = 32,
ConcessionPaidByBranch = 33,
ConcessionReasonDisplay = 34,
RequestorConcessionCommentsDisplay = 35,
ManagerConcessionCommentsDisplay = 36,
AllowPriceConcessionsBeforeLocking = 37,
AllowRateConcessionsBeforeLocking = 38,
SendConcessionEmailNotifications = 39,
ConcessionCommentsCharacterLimit = 40,
CCMailboxForConcessions = 41,
GeneralLockDeskMailboxForConcessions = 42,
}

export enum LockingConfigurationTypeEnum {
None = 0,
RulesForLock = 1,
RulesForConcession = 2,
RulesForCancellation = 3,
RulesForRateConcession = 4,
}

export enum LockprocesstypeEnum {
Manual = 0,
}

export enum LockStatusTypeEnum {
NotLocked = 0,
LockRequested = 1,
LockPending = 2,
LockExpirationDate = 3,
Locked = 4,
LockConfirmed = 5,
ExtensionRequested = 6,
CancellationRequested = 7,
LockCancelled = 8,
LockDenied = 9,
TestSubmitLockRequest = 10,
RegistrationRequested = 11,
Registered = 12,
}

export enum LookupCategoryEnum {
UndefinedLookupCategory = 0,
AbsencereasonCategory = 1,
AccounttypeCategory = 2,
ActivitymodeCategory = 3,
ActivitystateCategory = 4,
AddliabilitytypeCategory = 5,
AddresstypesCategory = 6,
AdjustedanticipatednetrentalincomeCategory = 7,
AdjustedborrowerincometypeCategory = 8,
AdverseCategory = 9,
AdversereasonCategory = 10,
AffiliatesubtypeCategory = 11,
AmortizationperiodCategory = 12,
AmortizationtypeCategory = 13,
AnticipatednetrentalincomeCategory = 14,
ApplicationtakenbyCategory = 15,
AppraisalBusinessContactPurchaseCategory = 16,
AppraisalBusinessContactRefinanceCategory = 17,
AppraisalcardtypesCategory = 18,
AppraisalContactsCategory = 19,
AppraisalmethodCategory = 20,
AppraisalorderstatusCategory = 21,
AppraisalpaidbyCategory = 22,
AppraisalpaymentmethodCategory = 23,
ArmidentifierCategory = 24,
ArmindextypeCategory = 25,
AssettypeCategory = 26,
AttachmenttypeCategory = 27,
AuditlogCategory = 28,
AusCategory = 29,
AusrecommendationsCategory = 30,
AustypesCategory = 31,
AvmmodelnameCategory = 32,
BankStatementsIncomeCategory = 33,
BasicLiabilityTypeCategory = 34,
BonafidepersonalreasonCategory = 35,
BorrowercommentCategory = 36,
BorrowerdebttypeCategory = 37,
BorrowerexpensetypeCategory = 38,
BorrowerincometypeCategory = 39,
BranchofserviceCategory = 41,
BranchstatusCategory = 42,
BrandsCategory = 43,
BusinessContactCategory = 44,
BusinessContactPurchaseCategory = 45,
BusinessContactRefinanceCategory = 46,
BuydownCategory = 47,
CalculationoptionsCategory = 48,
CashoutdropdownoptionsCategory = 49,
CertificationidCategory = 50,
CitizenresidencytypeCategory = 51,
CitizenshipCategory = 52,
CompliancecheckerrorsCategory = 53,
Conc2ndrepaymentstructureCategory = 54,
ConcessiontypeCategory = 55,
Concurrent2ndmortgageCategory = 56,
ConditionClearsTypeCategory = 57,
ConditionDueTypeCategory = 58,
ConditionGroupCategoryTypeCategory = 59,
ConditionItemStatusTypeCategory = 60,
ConditionObtainsToTypeCategory = 61,
ConditionSecurityLevelTypeCategory = 62,
ConditionSourceTypeCategory = 63,
ConditionTypeCategory = 64,
CondostatusCategory = 65,
ConstructionmethodCategory = 66,
ContactstatusCategory = 67,
ContextualtypesCategory = 68,
CostamountmethodmiCategory = 69,
CostamountmethodpurchasehoiCategory = 70,
CostamountmethodpurchasetaxCategory = 71,
CostamountmethodrefitaxCategory = 72,
CostpaidbyCategory = 73,
CostpaidbyotherCategory = 74,
CostpaidtoCategory = 75,
CostpaidwhenCategory = 76,
CostsectionCategory = 77,
CounselingconfCategory = 78,
CounselingformatCategory = 79,
CreditliencommentredCategory = 80,
CreditReportScrubItemsCategory = 81,
CreditscoresourceCategory = 82,
DebtcommentCategory = 83,
DesigntypeCategory = 84,
DocumentcategoryCategory = 86,
DocumentclassCategory = 87,
DownpaymenttypesourceCategory = 89,
EmailListForSynchInitiatedCategory = 90,
EmploymentstatusCategory = 91,
EngharpkeywordlistCategory = 92,
EstatetypeCategory = 93,
EstimatedthirdpartycostsCategory = 94,
EthnicityCategory = 95,
ExtensiontypeCategory = 96,
FhaproductsCategory = 97,
FicoscoreCategory = 98,
FicscoreCategory = 99,
FirsttimehomebuyerCategory = 100,
FreeConsultPurposeOfLoansCategory = 101,
HarpfilterkeywordlistCategory = 102,
HarpfilterkeywordlistfanniemaeCategory = 103,
HarpfilterkeywordlistfreddiemacCategory = 104,
HarpfilterkeywordlistocwenCategory = 105,
HarpindicatortypesCategory = 106,
HedgeloantypeCategory = 107,
HomebuyingCategory = 108,
HouseholdannualincomeCategory = 109,
HouseholddeductionsCategory = 110,
HouseholdtitletypesCategory = 111,
ImpoundstypeCategory = 112,
ImpoundstypeGridCategory = 113,
InterestaccrualmethodCategory = 114,
InterestcalctypeCategory = 115,
InterestonlyCategory = 116,
InvestorcollatprgmCategory = 117,
InvestorextdaysCategory = 118,
InvestorextpoliciesCategory = 119,
InvestorremittancetypeCategory = 120,
IpatypeCategory = 121,
LCategory = 122,
LeadstatusCategory = 123,
LedgerEntryNameCategory = 124,
LegalEntityTypeCategory = 125,
LegalstructureCategory = 126,
LendercreditspaidbyCategory = 127,
LendercreditstypesCategory = 128,
LiabilitiesdebtcommentCategory = 129,
LiabilityTypesCategory = 130,
LienpositionsCategory = 131,
LienprioritytypeCategory = 132,
LifeOfLoanSavingsCategory = 133,
LoanamortizationtypeCategory = 134,
LoanDecisionStatusTypeCategory = 135,
LoanlockroundingtypeCategory = 136,
LoanoriginatorCategory = 137,
LoanpurposeCategory = 138,
LoanstatusCategory = 139,
LoantransactiontypeCategory = 140,
LockingadjustmenttypeCategory = 141,
LockingconfigurationtypeCategory = 142,
LockstatusCategory = 143,
LocktypeCategory = 144,
LopaidbyCategory = 145,
LopricepaidbyCategory = 146,
LotsizeunittypesCategory = 147,
MannerofpaymentratingCategory = 148,
MannertitleheldCategory = 149,
MaritalstatusCategory = 150,
MicompanyCategory = 151,
MilestonestatusCategory = 152,
MilitarybranchCategory = 153,
MilitaryincometypeCategory = 154,
MilitaryserviceCategory = 155,
MilitaryservicestatusCategory = 156,
MiscellaneousdebttypeCategory = 157,
MisourceCategory = 158,
MortgagehistoryCategory = 159,
MortgageInsuranceTerminationCategory = 160,
MortgageinsurancetypeCategory = 161,
MortgageratesCategory = 162,
MortgagetypeCategory = 163,
MylistdropdownoptionsCategory = 164,
NonTraditionalCreditTypesCategory = 165,
NtbbenefitCategory = 166,
OfferingidentifierCategory = 167,
OrderappraisalsplitbuttonmenuCategory = 168,
OriginaltermCategory = 169,
OriginaltermperiodCategory = 170,
OthercreditsCategory = 171,
OtherincomeCategory = 172,
OwnershipTypesCategory = 173,
PayeecodeCategory = 174,
PaymentfrequencyCategory = 175,
PaymentmethodsCategory = 176,
PaymentperiodoptionsCategory = 177,
PeriodpaymentmonthCategory = 178,
PhonetypeCategory = 179,
PledgetassetcommentCategory = 180,
PledgetassetloantypeCategory = 181,
PriorpropertytitleCategory = 182,
ProductcategorytypeCategory = 183,
ProjecttypeCategory = 184,
PropertyCategory = 185,
PropertyGridCategory = 186,
PropertyShortCategory = 187,
PropertyattachmenttypeCategory = 188,
PropertyexpensespayperiodCategory = 189,
PropertyrightsCategory = 190,
PropertytypeNonsubjectpropertyCategory = 191,
PropertytypeSubjectpropertyCategory = 192,
ProviderrelationshiptypeCategory = 193,
PstoolsearchcriteriaCategory = 194,
PurposeofrefinanceCategory = 195,
RaceCategory = 196,
ReferraltypeCategory = 197,
RefiprogramidCategory = 198,
RegularincometypeCategory = 199,
RelatedloaninvestorCategory = 200,
RentalincometypeCategory = 201,
RequestappraisalsplitbuttonmenuCategory = 202,
SearchcriteriaamortizationtypeCategory = 203,
SearchcriteriaarmfixedtermsCategory = 204,
SearchcriterialoantermsCategory = 205,
SearchcriterialoantypesCategory = 206,
SecondmortgageCategory = 207,
SecondmortgagepayoffCategory = 208,
SectionoftheactCategory = 209,
SectionTypeCategory = 210,
SecurityquestionCategory = 211,
SellerTypesCategory = 212,
SendMyReportShowTrueClosingCostCategory = 213,
SendingmethodsCategory = 214,
ServiceproviderCategory = 215,
ServicingtransferstatustypeCategory = 216,
ServicingtypeCategory = 217,
SexCategory = 218,
StatesCategory = 219,
SubSectionTypeCategory = 220,
TaxpayeridtypeCategory = 221,
TimetocloseoptionsCategory = 222,
TitlecostincludedtypeCategory = 223,
TitleheldinCategory = 224,
TitlevestedasCategory = 225,
TpoadminfeeCategory = 226,
TpocompensationCategory = 227,
TypeofpropertyCategory = 228,
UlddprojecttypeCategory = 229,
UnitnumberCategory = 230,
UpfrontpreferredpaymentperiodCategory = 231,
UsdaguaranteefeetypeCategory = 232,
VacalculatorsCategory = 233,
ValoanpurposesCategory = 234,
VaregionsCategory = 235,
WhenwassecondopenedCategory = 236,
WithdrawnfromhelocinpastCategory = 237,
YesnoCategory = 238,
DisclosuresstatusenumCategory = 239,
BorrowerneedstatusCategory = 240,
CocfieldtypeCategory = 241,
TrackedworkflowstatusCategory = 242,
TrackedworkflowtypeCategory = 243,
CocreasontypeCategory = 244,
DocumentacceptancestatusCategory = 245,
DocumentdeliverymethodCategory = 246,
DocumentroleingroupCategory = 247,
DocumentsourceCategory = 248,
DocumentstatusCategory = 249,
LosexportstatusCategory = 250,
DoyoupaymortgageinsuranceCategory = 251,
BorrowerviewabletypeCategory = 252,
EmploymenttypeCategory = 253,
LoLSavingsCategory = 254,
IntegrationstatusdetailtypesCategory = 256,
AmortizationindicatorCategory = 257,
DocumentfolderCategory = 258,
LoanchanneltypeCategory = 259,
CreditrunbuttonmenuCategory = 260,
ProductconfigurationlienpositionsCategory = 278,
LoanbalancetypeCategory = 279,
RealtortypeCategory = 280,
PriceRoundingCategory = 281,
FeecategorytypesCategory = 282,
PaidbytypesCategory = 283,
PaidtotypesCategory = 284,
TolerancetypesCategory = 285,
VaallowabletypesCategory = 286,
ProductclassificationtypeCategory = 288,
CocfieldtypereasonCategory = 289,
CocdescriptionCategory = 291,
FeetypeCategory = 292,
PricingengineCategory = 293,
PreferredDayToCallCategory = 294,
ProductconfigurationpmiterminationmethodtypeCategory = 296,
ProductconfigurationlockstartdatetypeCategory = 298,
ProductconfigurationloantypeCategory = 299,
ProductconfigurationloanbalancetypeCategory = 300,
ProductconfigurationarmindextypeCategory = 301,
ProductconfigurationaustypeCategory = 302,
ProductconfigurationdeliverytypeCategory = 303,
ProductconfigurationamortizationtypeCategory = 304,
MortgagepaymentmethodCategory = 306,
MortgagepaymenttimeperiodCategory = 307,
FamilysizeCategory = 308,
RelativerelationshiptypeCategory = 315,
WorkflowtypeCategory = 440,
SecurelinkemailtemplateactivityCategory = 450,
SignaturelineCategory = 451,
WorkflowTaskStatusTypeCategory = 452,
WorkflowStepStatusTypeCategory = 453,
DeductiontypeCategory = 454,
PreferredTimeToCallCategory = 455,
LoanstepstatusCategory = 460,
RelationshipTypeCategory = 470,
ReportfrequencytypeCategory = 471,
MediaTypesCategory = 480,
PastcreditrecordCategory = 482,
AppraisalactionbuttonmenuCategory = 487,
AppraisalintegrationactionbuttonmenuCategory = 488,
MilitaryServiceTypeCategory = 490,
EntityTypeCategory = 491,
MIRateTypeCategory = 492,
AmericanIndianOrAlaskaNativeCategory = 495,
OtherAsianCategory = 496,
OtherHispanicOrLatinoCategory = 497,
OtherPacificIslandersCategory = 498,
EvoaCategory = 500,
StructuretypeCategory = 501,
LockprocesstypeCategory = 502,
ProductconfigurationperdieminterestperiodtypeCategory = 503,
PropertystructuretypeCategory = 504,
ConcessionoptionsCategory = 505,
ConcessionreasontypeCategory = 506,
ProductgroupCategory = 507,
PaymentEligibilityTypeCategory = 508,
ProductconfigurationdefaultausCategory = 509,
AdversedenialreasonCategory = 510,
AdversewithdrawnreasonCategory = 511,
AdverserequestreceivedviaCategory = 512,
EntitlementUsedCategory = 513,
TX50a6AllowableTypeCategory = 514,
LoanProductSpecialProgramCategory = 515,
LoanProductSpecialFeatureCategory = 516,
LoanProductSettingTypeCategory = 517,
LoanProductSourceObjectTypeCategory = 518,
MCCRateCategory = 519,
MCCAppliedCategory = 520,
MCCAppliedAdminCategory = 521,
MortgageInsuranceOptionCategory = 522,
SuffixCategory = 523,
SearchcriteriadesiredproductoptionCategory = 524,
LosFieldTypeCategory = 525,
LosSectionTypeCategory = 526,
CSharpTypeCategory = 527,
PropertyRoadTypeCategory = 528,
PropertyWaterSupplyCategory = 529,
PropertySewageSystemCategory = 530,
PublicUtilityConnectionCategory = 531,
CrmVelocifySettingCategory = 532,
GiftandgrantsrelationshipCategory = 533,
RentalPropertyManagedByCategory = 534,
OwnedPropertyDispositionStatusTypeCategory = 535,
QueueCategory = 536,
EmptyDataPriorityCategory = 537,
CreditAuthorizationMethodCategory = 538,
LosFailedReasonCategory = 539,
LosFailedTypeCategory = 540,
LosFailedStatusCategory = 541,
TpouwfeeCategory = 545,
SearchcriteriacustomtermsCategory = 546,
UpfrontmioptionCategory = 547,
HopeloanportalCategory = 548,
LosListTypeCategory = 549,
LosDisplayTypeCategory = 550,
LosDisplayRuleCategory = 551,
LosGroupTypeCategory = 552,
MylistpropertytypeCategory = 553,
VantbbenefitCategory = 554,
LoanFieldHistoryTypeCategory = 555,
LoanFieldDataTypeCategory = 556,
RegistrationstatusCategory = 557,
UWSubmissionTypeDOCategory = 558,
UWSubmissionTypeLPACategory = 559,
Form4506typeCategory = 560,
LoantaxdatasourceCategory = 561,
TranscripttypeCategory = 562,
LoantaxdatatitleCategory = 564,
ProductconfigurationmipaidbytypeCategory = 565,
MipaidbyCategory = 566,
OwnershipInterestTypeCategory = 567,
CounselingTypeCategory = 568,
CounselingFormatTypeCategory = 569,
ConstructionTypeCategory = 570,
IndianCountryLandTenureCategory = 571,
BankruptcyTypeCategory = 572,
ConstructionToPermClosingTypeCategory = 573,
UnitDesignatorTypeCategory = 574,
CounselingWorkshopTypeCategory = 575,
PreferredLanguageCategory = 576,
URLAFormTypeCategory = 577,
NullableBooleanEnumCategory = 578,
MylistloantypeCategory = 579,
TranscriptrequestedbusinessCategory = 580,
TranscriptrequestedindividualCategory = 581,
ProductGroupTypeCategory = 582,
PropertytypeCategory = 583,
IncomeTypesCategory = 585,
URLALoanAppVersionCategory = 586,
DomesticRelationshipRightsCategory = 587,
LookupFilterCategory = 588,
SelectOneYesNoCategory = 589,
IncomeVerificationReportTypeCategory = 591,
}

export enum LookupFilterTypeEnum {
Unspecified = 0,
Default = 1,
URLA2009 = 2,
URLA2020 = 4,
}

export enum LookupTypeEnum {
Default = 0,
Superset = 1,
}

export enum LosDisplayRuleEnum {
None = 0,
ShowAlways = 1,
AmortizationARM = 2,
PurchaseTransaction = 3,
RefiTransaction = 4,
CoborrowerExists = 5,
IsBorrower = 6,
ConventionalOrUSDA = 7,
FHA = 8,
MorgageTypeVA = 9,
ARMAndConventionalOrUSDA = 10,
ARMAndFHA = 11,
ARMAndVA = 12,
}

export enum LosDisplayTypeEnum {
None = 0,
Text = 1,
ShortDate = 2,
LongDate = 3,
Currency = 4,
Rate = 5,
MilestoneStatus = 6,
StructureType = 7,
TwoDecimal = 8,
FourDecimal = 9,
TwoInteger = 10,
ThreeInteger = 11,
FourInteger = 12,
AmortType = 13,
ThreeDecimal = 14,
LockStatus = 15,
CurrencyMonthly = 16,
SquareFeet = 17,
LotSize = 18,
CashSource = 19,
LockStatusText = 20,
PropertyTypeText = 21,
DownPaymentText = 22,
LoanMilestoneStatusText = 23,
EnumList = 24,
}

export enum LOSExportStatusEnum {
None = 0,
ExportInProgress = 1,
ExportCompleted = 2,
ExportFailed = 3,
}

export enum LosFieldTypeEnum {
None = 0,
LoanLoanStatus = 1,
LoanMilestoneStatus = 2,
SubjectPropertyStreet = 3,
SubjectPropertyCity = 4,
SubjectPropertyState = 5,
SubjectPropertyZip = 6,
LoanNoteRate = 7,
LoanBaseAmount = 8,
LoanTotalAmount = 9,
LosAPR = 10,
LosProposedHousingExpensesPI = 11,
LosLoanLastModifiedDate = 12,
LosLeSentDate = 13,
LosLockStatus = 14,
LosDisclosureTrackingListBy = 15,
LosTimeStamp = 16,
LoanNumber = 17,
LoanOfficerLosId = 18,
LoanOriginationSystem = 19,
SubjectPropertyPropertyType = 20,
SubjectPropertyOccupancyType = 21,
LoanEstimatedValue = 22,
LoanClosingDate = 23,
LoanLoanProgram = 24,
LoanAmortizationType = 25,
LoanLoanTerm = 26,
LoanTermDueIn = 27,
LoanInterestRate = 28,
LoanMilestoneStatusFromLog = 29,
LOSLoanStatus = 30,
LOSSubLoanStatus = 31,
LoanEstimateIssued = 32,
QualifyingRate = 33,
PropertyType = 34,
Units = 35,
AppraisedValue = 36,
LotSize = 37,
SizeofHome = 38,
YearBuilt = 39,
Structure = 40,
PurchasePrice = 41,
DownPayment = 42,
DownPaymentSource = 43,
EstimatedValue = 44,
LTV = 45,
CLTV = 46,
HCLTV = 47,
BrokerCompensation = 48,
BorrowerAsset = 49,
CoBorrowerAsset = 50,
TotalAssets = 51,
DecisionScore = 52,
BorrowerEquifaxScore = 53,
CoBorrowerEquifaxScore = 54,
BorrowerExperianScore = 55,
CoBorrowerExperianScore = 56,
BorrowerTransUnionScore = 57,
CoBorrowerTransUnionScore = 58,
BorrowerEmploymentIncome = 59,
CoBorrowerEmploymentIncome = 60,
TotalIncome = 61,
DTIForConventional = 62,
DTIForHFA = 63,
DTIForVA = 64,
QualifyingDTIForARMConOrUSDA = 65,
QualifyingDTIForARMFHA = 66,
QualifyingDTIForARMAndVA = 67,
HousingDTIForConOrUSDA = 68,
HousingDTIForFHA = 69,
HousingDTIForVA = 70,
PropertyTax = 71,
HomeownersInsurance = 72,
FloodInsurance = 73,
HOADues = 74,
LoanProgramName = 75,
Term = 76,
BasePrice = 77,
FinalPrice = 78,
TotalPriceAdjustments = 79,
AmortizationType = 80,
IndexType = 81,
IndexValue = 82,
Margin = 83,
CapsFirstSubLife = 84,
EstimatedClosingCosts = 85,
CashToAndFromBorrower = 86,
BorrowerLiabilities = 87,
CoBorrowerLiabilities = 88,
TotalLiabilities = 89,
LoanEstimateCreated = 90,
LoanEstimateSigned = 91,
ClosingDisclosureCreated = 92,
ClosingDisclosureIssued = 93,
ClosingDisclosureSigned = 94,
LockRequestedDate = 95,
LockFulfilledDate = 96,
InitialLockExpirationDate = 97,
CurrentLockExpirationDate = 98,
LosLockStatusText = 99,
BorrowerCashType = 100,
PropertyTypeText = 101,
DownPaymentText = 102,
LastModifiedBy = 103,
LoanAppDate = 104,
LoanFundedDate = 105,
LenderLoanNumber = 106,
LoanMilestoneStatusOption = 107,
InitialDisclosureSystem = 108,
SubjectPropertyPUDIndicator = 109,
OtherSupplementalInsurance = 110,
}

export enum LosSectionTypeEnum {
None = 0,
Status = 1,
LoanInformation = 2,
PropertyInformation = 3,
LoanDates = 4,
PaymentInformation = 5,
AssetInformation = 6,
CreditScoreInformation = 7,
IncomeInformation = 8,
ARMInformation = 9,
LiabilityInformation = 10,
}

export enum MannerOfPaymentRatingEnum {
Unknown = 0,
Late30Days = 1,
Late60Days = 2,
Late90Days = 3,
LateOver120Days = 4,
ChargeOff = 5,
Collection = 6,
}

export enum MannerTitleHeldEnum {
CommunityProperty = 0,
InaCorporation = 1,
InaTrust = 2,
JointTenants = 3,
MarriedMan = 4,
MarriedWoman = 5,
SingleMan = 6,
SingleWoman = 7,
SoleOwner = 8,
TenancyByEntirety = 9,
TenantsInCommon = 10,
ToBeDecidedInEscrow = 11,
UnmarriedMan = 12,
UnmarriedWoman = 13,
Other = 14,
JointTenancyWithRightOfSurvivorship = 15,
LifeEstate = 16,
}

export enum MaritalStatusTypeEnum {
Married = 0,
Separated = 1,
Unmarried = 2,
CivilUnion = 3,
DomesticPartnership = 4,
RegisteredReciprocalBeneficiaryRelationship = 5,
Other = 6,
SelectOne = -1,
}

export enum MCCAppliedEnum {
None = 0,
UseAsIncome = 1,
OffsetMtgPmt = 2,
}

export enum MCCRateEnum {
None = 0,
Percent5 = 1,
Percent10 = 2,
Percent15 = 3,
Percent20 = 4,
Percent25 = 5,
Percent30 = 6,
Percent35 = 7,
Percent40 = 8,
Percent45 = 9,
Percent50 = 10,
}

export enum MediaTypeEnum {
unknown = 0,
rtf = 1,
octetStream = 2,
javascript = 3,
json = 4,
formDataUrlencoded = 5,
xml = 6,
zip = 7,
pdf = 8,
doc = 9,
xls = 10,
xlsx = 11,
ppt = 12,
pptx = 13,
mpeg = 14,
vorbis = 15,
formDataMultipart = 16,
css = 17,
html = 18,
csv = 19,
plain = 20,
png = 21,
jpeg = 22,
gif = 23,
}

export enum MessageTypeEnum {
Info = 1,
Warning = 2,
Error = 4,
}

export enum MiddleWareLosEnum {
BytePro = 0,
Integra = 1,
}

export enum MilestoneStatusTypeEnum {
Prospect = 1,
Incomplete = 2,
Processing = 3,
PreApproved = 4,
Approved = 5,
DocsOut = 6,
Closed = 7,
Funded = 8,
Cancelled = 9,
Unsubmitted = 10,
Registered = 11,
Submitted = 12,
Rejected = 13,
Adverse = 14,
AppCompleted = 15,
Underwriting = 16,
Completed = 17,
}

export enum MilitaryBranchOrServiceTypeEnum {
AirForce = 0,
Army = 1,
Marines = 2,
Navy = 3,
Other = 4,
CoastGuard = 5,
SpaceForce = 6
}

export enum MilitaryServiceTypeEnum {
None = 1,
Serving = 2,
Retired = 3,
Reserve = 4,
Spouse = 5,
}

export enum MiPaidByEnum {
BorrowerPaidBPMI = 1,
LenderPaidLPMI = 2,
EnterprisePaidEPMI = 8,
}

export enum MortgageInsuranceOptionEnum {
NoMIDiscount = 1,
FHLMCRate = 2,
FNMARate = 3,
}

export enum MortgageInsuranceTerminationEnum {
LTV78orMidpointTermination = 0,
MidpointTermination = 1,
NoAutoTermination = 2,
ElevenYearRemoval = 3,
}

export enum MortgageInsuranceTypeEnum {
All = 0,
BorrowerPaidMI = 1,
LenderPaidMI = 2,
NoMI = 3,
BorrowerPaidMISinglePremiumRefundable = 4,
BorrowerPaidMISinglePremiumNonRefundable = 5,
BorrowerPaidMISplitPremiumRefundable = 6,
BorrowerPaidMISplitPremiumNonRefundable = 7,
EnterprisePaidMI = 8,
}

export enum MortgagePaymentMethodEnum {
Autopay = 1,
Online = 2,
Mail = 3,
ByPhone = 4,
}

export enum MortgagePaymentTimePeriodEnum {
ByThe1st = 1,
ByThe5th = 2,
ByThe15th = 3,
AfterThe15th = 4,
}

export enum MortgageTypeEnum {
Conventional = 0,
FHA = 1,
ConventionalJumbo = 2,
ConventionalSuperJumbo = 3,
VA = 4,
USDA = 5,
}

export enum NetRentalTypeEnum {
Current = 0,
Proposed = 1,
}

export enum NonTraditionalCreditTypeEnum {
None = 0,
InsufficientCreditHistory = 1,
SignificantErrorsScore = 2,
}

export enum NotificationEventTypeEnum {
None = 0,
AUSDOCompleted = 1,
AUSDORequested = 2,
AUSLPACompleted = 3,
AUSLPARequested = 4,
BNLDocumentUploaded = 5,
BNLGenerated = 6,
BNLUpdated = 7,
CreditCompleted = 8,
CreditReportRequested = 9,
DisclosureMailed = 10,
DisclosureReceived = 11,
DisclosureRequested = 12,
DisclosureSigned = 13,
DisclosureViewed = 14,
DocumentUploaded = 15,
EConsentAccepted = 16,
EConsentRejected = 17,
EVerifyCompleted = 18,
ExitLoan = 19,
LoanApplicationCompleted = 20,
LoanApplicationCreated = 21,
LoanApplicationTRIDStart = 22,
LoanApplicationUpdated = 23,
PricingUpdated = 24,
ProductPricingCompleted = 25,
ProductPricingSearchCompleted = 26,
QuoteCreated = 27,
RegisterLoanCompleted = 28,
CreditFailed = 29,
LoanApplicationCreatedByCS = 30,
RegisterLoanFailed = 31,
DisclosureFailed = 32,
AUSLPAFailed = 33,
AUSDOFailed = 34,
LoanApplicationCreatedInLC = 35,
EVOAReportCompleted = 36,
BrokerAuthenticationFailed = 37,
TokenCreateFailed = 38,
TPODataUpdated = 39,
ApplicationAdversed = 40,
RegisterLoanInitiated = 41,
}

export enum NtbBenefitEnum {
AvoidForeclosure = 1,
LowerInterestRate = 2,
LowerMonthlyPayment = 3,
LowerInterestRateOrLowerTermAndCostsRecouped = 4,
LowerDuration = 5,
LowerAmortizationSchedule = 6,
EliminateFeatureNegativeAmortization = 7,
EliminateFeatureBalloonPayment = 8,
EliminatePrivateMortgageInsurance = 9,
ChangeAdjustableRateToFixedRate = 10,
CashoutReasonable = 11,
CashoutReasonableBonaFiedPersonalNeed = 12,
CashoutSignificantPurpose = 13,
ConsolidateLoans = 14,
ConsolidateDebts = 15,
NewLoanGuaranteed = 16,
NewLoanQualifiedMortgage = 17,
DTIBelowThreshold = 18,
}

export enum NullableBooleanEnum {
No = 0,
Yes = 1,
SelectOne = -1,
}

export enum ObtainsToTypeEnum {
Broker = 1,
LoanOfficer = 2,
LoanAssistant = 3,
LoanPro = 4,
Underwriter = 5,
Escrow = 6,
Title = 7,
Docs = 8,
Funder = 9,
QC = 10,
PostClose = 11,
}

export enum OfferingidentifierEnum {
SelectOne = 0,
_241HomePossible = 241,
_250HomePossibleAdvantage = 250,
_251HomePossibleAdvantageForHFAs = 251,
_310ReliefRefinanceOpenAccess = 310,
}

export enum OrderAppraisalSplitButtonEnum {
OrderAppraisal = 1,
CancelOrder = 2,
}

export enum OrderStatusEnum {
Active = 0,
Placed = 1,
Canceled = 2,
}

export enum OrderTypeEnum {
None = 0,
Appraisal = 1,
}

export enum OtherCreditType {
CashDepositOnSales = 0,
SellerCredit = 1,
LenderCredit = 2,
RelocationFunds = 3,
EmployerAssistedHousing = 4,
LeasePurchaseFund = 5,
Other = 6,
BorrowerPaidfees = 7,
LenderPointCredit = 8,
}

export enum OwnedPropertyDispositionStatusTypeEnum {
Rent = 1,
Retain = 2,
}

export enum OwnershipInterestTypeEnum {
GreaterThanOrEqualTo25Percent = 1,
LessThan25Percent = 2,
}

export enum OwnershipStatusTypeEnum {
Own = 0,
Rent = 1,
RentFree = 2,
None = -1,
}

export enum PageNavigationCategoryEnum {
Undefined = 0,
EVerify = 1048576,
Miscellaneous = 2097152,
Pricing = 16777216,
SaveForLater = 33554432,
PreLoanNavigation = 67108864,
MobilePricing = 134217728,
LoanApplication = 268435456,
MyNextStep = 536870912,
BorrowerPreferences = 1073741824,
}

export enum PageNavigationStateEnum {
Undefined = 0,
Clover = 1,
EVerifyIFrame = 1048577,
EVerifySelectAccounts = 1048578,
EVerifySuccess = 1048579,
ForgotPassword = 2097153,
Pricing = 16777217,
PricingDetail = 16777218,
AccountCreation = 33554433,
SeeYouLater = 33554434,
PaymentCalculator = 67108865,
AffordabilityCalculator = 67108866,
PaymentCalculatorMain = 67108867,
AffordabilityCalculatorMain = 67108868,
MobilePricing = 134217729,
MobilePricingSearch = 134217730,
MobilePricingResult = 134217732,
MobilePricingDetail = 134217736,
MobilePricingSortFilter = 134217744,
BrokerLanding = 268435457,
LoanPurpose = 268435458,
BorrowerPersonalInfo = 268435459,
CoBorrowerPersonalInfo = 268435460,
PropertyInfo = 268435461,
BorrowerAddressInfo = 268435462,
CoBorrowerAddressInfo = 268435463,
BorrowerEmployment = 268435464,
BorrowerPreviousEmployment = 268435465,
CoBorrowerEmployment = 268435466,
CoBorrowerPreviousEmployment = 268435467,
RetirementIncome = 268435468,
OtherIncome = 268435469,
Assets = 268435470,
BorrowerGovernmentMonitoring = 268435471,
CoBorrowerGovernmentMonitoring = 268435472,
Declarations = 268435473,
Summary = 268435474,
AdditionalBorrower = 268435475,
Credit = 268435476,
Account = 268435477,
CreditResults = 268435478,
Success = 268435479,
CreditError = 268435480,
WaitForCredit = 268435481,
GoodBye = 268435482,
BorrowerNeedsList = 268435483,
BorrowerNeedsListBulkUpload = 268435484,
DashBoard = 536870913,
DisclosureInstructions = 536870914,
DisclosureSigning = 536870915,
OrderAppraisal = 536870916,
DocumentUpload = 536870917,
LoanReview = 536870918,
SummaryReview = 536870919,
EConsentSettings = 536870920,
ViewAppraisal = 536870921,
MyDocuments = 536870922,
PinAuthentication = 536870923,
MyLoans = 536870924,
MyAccount = 536870925,
BulkUpload = 536870926,
LoanComplete = 536870928,
DashboardPricing = 553648143,
AlertPreferences = 1073741825,
ActivationCode = 1073741826,
Signout = 1073741827,
}

export enum PartyTypeEnum {
Borrower = 1,
CoBorrower = 2,
LoanConcierge = 3,
LoanProcessor = 4,
ImpersonatedConciergeId = 5,
CallCenter = 6,
LeadSource = 7,
PartnerLeadSource = 8,
BuyersAgent = 9,
SellersAgent = 10,
LoanOfficer = 11,
Investor = 12,
}

export enum PasswordAuthenticationStatusEnum {
ValidPassword = 1,
InvalidPassword = 2,
InvalidUserName = 3,
InActiveUser = 4,
AccountLocked = 5,
}

export enum PaymentEligibilityTypeEnum {
MultipleLate30DaysSubjectProperty12Months = 1,
AnyLate30DaysSubjectProperty12Months = 2,
Late30DaysAnyProperty12Months = 3,
CurrentOnVAMortgage = 4,
Late30AnyProperty6Months = 5,
PaymentIneligible = 6,
}

export enum PaymentMonthEnum {
Unspecified = 0,
January = 1,
February = 2,
March = 3,
April = 4,
May = 5,
June = 6,
July = 7,
August = 8,
September = 9,
October = 10,
November = 11,
December = 12,
}

export enum PeriodTypeEnum {
None = 0,
Monthly = 1,
Annually = 2,
Weekly = 3,
BiWeekly = 4,
Quarterly = 5,
SemiAnnually = 6,
Hourly = 7,
Month2x = 8,
}

export enum PledgedAssetCommentTypeEnum {
DoNotPayoff = 0,
PaidOffFreeAndClear = 1,
PayoffAtClose = 2,
PayoffAtClosingAndDontCloseAccount = 3,
PayoffAtClosingAndCloseAccount = 4,
Sold = 5,
NotMyLoan = 6,
PendingSale = 7,
NotAMortgage = 8,
Duplicate = 9,
}

export enum POSNotificationStatusTypeEnum {
Pending = 0,
Completed = 1,
Failed = 2,
}

export enum POSNotificationTypeEnum {
LoanAppCompleted = 0,
}

export enum PreApprovalLetterPredicateTypeEnum {
State = 1,
Product = 2,
}

export enum PreApprovalStatusEnum {
None = 0,
Started = 1,
Requested = 2,
Issued = 3,
Expired = 4,
Reissued = 5,
Completed = 6,
}

export enum PreApprovalTemplateTypeEnum {
Undefined = 1,
PreApprovalLetter = 2,
PreQualificationLetter = 3,
OnlineBorrowerTBDProperty = 4,
OfflineBorrowerTBDProperty = 5,
OnlineBorrowerWithProperty = 6,
POC = 7,
}

export enum PreferredContactOrderType {
Unknown = 0,
Preferred = 1,
AlternativeOne = 2,
AlternativeTwo = 3,
}

export enum PreferredDayToCallEnum {
Mon = 1,
Tue = 2,
Wed = 3,
Thu = 4,
Fri = 5,
Sat = 6,
}

export enum PreferredDayToCallTypeEnum {
Mon = 1,
Tue = 2,
Wed = 4,
Thu = 8,
Fri = 16,
Sat = 32,
}

export enum PreferredLanguageTypeEnum {
SelectOne = 0,
Chinese = 1,
English = 2,
Korean = 4,
Spanish = 8,
Tagalog = 16,
Vietnamese = 32,
Other = 64,
IDontWishToRespond = 128
}

export enum PreferredTimeToCallEnum {
Time1 = 11,
Time2 = 12,
Time3 = 13,
Anytime = 14,
}

export enum PreferredTimeToCallTypeEnum {
Time1 = 1,
Time2 = 2,
Time3 = 4,
Anytime = 8,
}

export enum PreQualificationLetterQuestionCategoryEnum {
TX = 1,
AZGeneral = 2,
AZBorrowerDocumentation = 3,
}

export enum PreQualificationLetterQuestionEnum {
Other = 1,
SignedApplication = 2,
CreditReportCreditScore = 3,
CreditScore = 4,
IncomeProvided = 5,
DebtsProvided = 6,
AssetsProvided = 7,
AvailableCashProvided = 8,
MonthlyPIPayment = 9,
MaritalStatus = 10,
LOLicenseNumber = 11,
LOFax = 12,
RelyingOnSaleOrLease = 13,
RelyingOnSellerConcessions = 14,
RelyingOnDownPayment = 15,
BorrowerProvidedWithHUD = 16,
DiscussionOfIncomeAssetsDebts = 17,
TriMergedResidentailCreditReport = 18,
Paystubs = 19,
W2s = 20,
PersonalTaxReturns = 21,
CorporateTaxReturns = 22,
Reserves = 23,
GiftDocumentation = 24,
CreditLiability = 25,
LoanTypeAmortizationType = 26,
AdditionalDocumentation = 27,
AdditionalItems = 28,
}

export enum PricingAdjustmentSectionTypeEnum {
None = 0,
Enterprise = 1,
LOPrice = 2,
FinalPrice = 3,
}

export enum PricingEngineEnum {
None = 0,
OptimalBlue = 1,
Engenious = 2,
MicroService = 3,
}

export enum PricingLoadOptionEnum {
GetProducts = 1,
GetProductDetails = 2,
GetIneligibleProducts = 4,
FullProductSearch = 7,
}

export enum PricingScenarioEnum {
ClosestToPrice = 0,
WorstCaseScenario = 1,
ParPriceScenario = 2,
}

export enum PricingTypeEnum {
None = 0,
ShopForRates = 1,
ShopForOffers = 2,
}

export enum PrivilegeType {
System = 0,
User = 1,
}

export enum ProductClassificationEnum {
NonAgency = 1,
AgencyHighBalance = 2,
AgencyConforming = 3,
AffordableHFA = 4,
AffordableHomePossible = 5,
AffordableHomeReady = 6,
FixedSecond = 7,
FHA203K = 8,
FHAStandard = 9,
FHAStandardHB = 10,
FHAStreamline = 11,
FHAStreamlineHB = 12,
HARPDURefi = 13,
HARPOpenAccess = 14,
VA = 15,
VAHB = 16,
VAIRRRL = 17,
VAIRRRLHB = 18,
USDA = 19,
AffordableMCM = 21,
AffordableHomePossibleAdvantage = 22,
}

export enum ProductclassificationtypeEnum {
NonAgency = 1,
AgencyHighBalance = 2,
AgencyConforming = 3,
AffordableHFA = 4,
AffordableHomePossible = 5,
AffordableHomeReady = 6,
FixedSecond = 7,
FHA203K = 8,
FHAStandard = 9,
FHAStandardHB = 10,
FHAStreamline = 11,
FHAStreamlineHB = 12,
HARPFNMADURefi = 13,
HARPFHLMCOpenAccess = 14,
VA = 15,
VAHB = 16,
VAIRRRL = 17,
VAIRRRLHB = 18,
USDA = 19,
AffordableMCM = 21,
AffordableHomePossibleAdvantage = 22,
}

export enum ProductconfigurationamortizationtypeEnum {
Fixed = 1,
AdjustableRate = 2,
GraduatedPaymentARM = 3,
GraduatedPaymentMortgage = 4,
GrowingEquityMortgageStep = 5,
}

export enum ProductconfigurationarmindextypeEnum {
_1YearLIBOR = 0,
_1YearTreasury = 1,
}

export enum ProductConfigurationAusTypeEnum {
DU = 1,
LP = 2,
InvestorAUS = 3,
Manual_Traditional = 4,
GUS = 5,
}

export enum ProductconfigurationdefaultausEnum {
DU = 0,
LPA = 1,
}

export enum ProductconfigurationdeliverytypeEnum {
BestEfforts = 1,
Mandatory = 2,
}

export enum ProductconfigurationlienpositionsEnum {
SelectOne = 0,
_1stTD = 1,
_2ndTD = 2,
}

export enum ProductconfigurationloanbalancetypeEnum {
LowBalance = 0,
HighBalance = 1,
NonConforming = 2,
}

export enum ProductconfigurationloantypeEnum {
Conventional = 0,
FHA = 1,
VA = 4,
USDARuralHouseing = 5,
}

export enum ProductconfigurationpmiterminationmethodtypeEnum {
N_A = 0,
FNMA = 1,
FHLMC = 2,
}

export enum ProductGroupTypeEnum {
Brokered = 0,
ChromeNotExpanded = 1,
ChromeExpanded = 2,
FHA = 3,
JumboRubyExceptSelect = 4,
JumboRubySelect = 5,
StateHousing = 6,
}

export enum ProductModificationStatusEnum {
None = 0,
Price = 1,
Rate = 2,
}

export enum ProductTypeEnum {
CommitmentPrelimReport = 1,
ClosingAndEscrowServices = 2,
TitleAndEscrowProducts = 3,
CreditReport = 4,
AuditReport = 5,
DriveReport = 6,
PricingLock = 7,
IntegrationTemplate = 8,
DU = 9,
LPA = 10,
ASSET_EVERIFICATION = 11,
DesktopOriginator = 12,
DocumentGeneration = 13,
ShopForRates = 14,
UpdatePricing = 15,
LOS = 16,
FeesEstimate = 17,
FeesActual = 18,
FeesRecordingOffice = 19,
FeesQuestions = 20,
TaxTranscript = 21,
ComplianceCheck = 22,
SubmitDocument = 23,
LoanComments = 24,
LeadStatus = 25,
Appraisal = 26,
LeadExport = 27,
GetSecurityToken = 28,
OBPlugin = 30,
MI = 32,
PrintAndShip = 33,
LockingWorkflow = 34,
RealTimeDocs = 35,
StoreDocumentImage = 36,
SpecialProgramFeature = 37,
UpdateAdjustments = 38,
ChargeCreditCard = 39,
ExpressEntry = 42,
Personator = 43,
ReferralSource = 44,
LoanUpdateProduct = 45,
ImportFromLOS = 46,
LeadImport = 47,
RetrieveDocumentList = 48,
LoanStatus = 49,
TrackingItems = 50,
FAM = 51,
UpdateLoanStatus = 52,
LoanContacts = 53,
BNL = 54,
DisclosureHistory = 56,
EventBroker = 57,
EConsent = 58,
ITP = 59,
UserAuthorization = 60,
GetExternalPPE = 61,
UpdatePriceAndFees = 62,
GetPricingRequest = 63,
Passcode = 64,
AccountLookup = 65,
GenerateUrl = 66,
ExportSync = 67,
ExportAsync = 68,
PreviewDisclosures = 69,
AdverseDocument = 70,
SelectDocument = 71,
DocumentList = 72,
SunWestGetSecurityToken = 73,
VOE_VOI = 74,
}

export enum PropertyAttachmentTypeEnum {
None = 0,
Attached = 1,
Detached = 2,
SemiDetached = 3,
}

export enum PropertyExpenseTypeEnum {
None = 0,
PropertyTax = 1,
HomeOwnerInurance = 2,
MortgageInsurance = 3,
HomeOwnerAssociationDues = 4,
FloodInsurance = 5,
}

export enum PropertyHeldTypeEnum {
Individual = 0,
Trust = 1,
Corporation = 2,
}

export enum PropertyTypeEnum {
None = 0,
SingleFamily = 1,
Condominium = 2,
PUD = 3,
ManufacturedHousingDoubleWide = 4,
ManufacturedHousingSingleWide = 5,
Cooperative = 6,
Condotel = 7,
Modular = 8,
Timeshare = 9,
NonWarrantableCondo = 10,
TownHouse = 11,
Commercial = 12,
MixedUse = 13,
MultiFamilyTwoToFourUnits = 14,
MobileHome = 15,
MultiFamilyMoreThanFourUnits = 16,
HomeAndBusinessCombined = 17,
Farm = 18,
Land = 19,
}

export enum PropertyUsageTypeEnum {
None = 0,
PrimaryResidence = 1,
InvestmentProperty = 2,
SecondVacationHome = 3,
}

export enum ProspectStatusTypeEnum {
NewProspect = 0,
Badcredit = 1,
Cancelled = 2,
DoNotCall = 3,
FollowUp = 4,
WarmLead = 5,
ApplicationStarted = 6,
LostToCompetition = 8,
NoBenefitRateAlert = 11,
NoEquity = 12,
NoIncome = 13,
NotLicensedInTheState = 14,
PreApprovalRequest = 16,
LoanApplicationPending = 17,
ApplicationCompleted = 19,
}

export enum ProviderRelationshipTypeEnum {
None = 0,
Affiliate = 1,
PSSP = 2,
}

export enum PSToolSearchCriteriaEnum {
LoanID = 1,
LoanNumber = 2,
BorrowerName = 3,
EmailAddress = 4,
SubjectPropertyAddress = 5,
BorrowerId = 6,
}

export enum PurposeOfRefinanceEnum {
None = 0,
CashOutDebtConsolidation = 1,
CashOutHomeImprovement = 2,
CashOutLimited = 3,
CashOutOther = 4,
CashOutTuitionExpenses = 5,
CashOutPurchaseHome = 6,
CashOutWithOriginalLender = 7,
NoCashOutRateTerm = 8,
NoCashOutOther = 9,
NoCashOutFHAStreamlined = 10,
NoCashOutFreddieOwned = 11,
NoCashOutStreamlined = 12,
NoCashOutWithOriginalLender = 13,
}

export enum QueueCategoryEnum {
MyLists = 1,
StandardLists = 2,
Disclosures = 3,
RateLockAndPricing = 4,
Appraisal = 5,
}

export enum RaceEnum {
Idonotwishtofurnishthisinformation = 0,
AmericanIndianorAlaskaNative = 1,
Asian = 2,
BlackorAfricanAmerican = 3,
NativeHawaiianorOtherPacificIslander = 4,
White = 5,
NotApplicable = 6,
}

export enum RaceTypeEnum {
None = 0,
AmericanIndianOrAlaskaNative = 1,
Asian = 2,
BlackOrAfricanAmerican = 4,
NativeHawaiianOrOtherPacificIslander = 8,
White = 16,
NotApplicable = 32,
IDoNotWishToFurnishThisInformation = 64,
}

export enum RealtorTypeEnum {
BuyersAgent = 1,
SellersAgent = 2,
DualAgent = 3,
}

export enum RefEntityType {
Loan = 1,
LoanApplication = 2,
Borrower = 3,
}

export enum RefinanceCommentTypeEnum {
None = 0,
DoNotPayoff = 1,
PayoffAtClosing = 2,
PayoffDontCloseAccount = 3,
PayoffCloseAccount = 4,
}

export enum RefinancePurposeTypeEnum {
LimitedCashOut = 0,
CashOut = 1,
RateAndTerm = 2,
}

export enum RegistrationResponseStatusEnum {
Success = 1,
PartialSuccess = 2,
Failed = 3,
}

export enum RegistrationStatusEnum {
None = 0,
Registered = 1,
Requested = 2,
Failed = 3,
Pending = 4,
Error = 5,
Partial = 6,
NotRegistered = 9,
}

export enum RelativeRelationshipTypeEnum {
Father = 1,
Mother = 2,
Brother = 3,
Sister = 4,
Son = 5,
Daughter = 6,
Grandfather = 7,
Grandmother = 8,
Aunt = 9,
Uncle = 10,
Cousin = 11,
Niece = 12,
Nephew = 13,
}

export enum RentalPropertyManagedByEnum {
Individual = 1,
ManagementCompany = 2,
}

export enum RequestAppraisalSplitButtonEnum {
RequestAppraisal = 1,
CancelOrder = 2,
}

export enum ResponseCodeTypeEnum {
Success = 0,
InvalidRequest = 1,
NotImplemented = 2,
}

export enum RightsTypeEnum {
FeeSimple = 0,
Leasehold = 1,
Other = 2,
}

export enum RoleEnum {
None = 0,
Administrator = 1,
Borrower = 2,
BranchManager = 3,
Business = 4,
ChannelManager = 5,
Concierge = 6,
DivisionManager = 7,
HVM = 8,
LoanAdmin = 9,
LoanOfficer = 10,
LoanOfficerAssistant = 11,
LoanProcessor = 12,
LockDesk = 13,
Manager = 14,
Public = 15,
SalesManager = 16,
SalesMarketing = 17,
SalesMarketingExternal = 18,
Secondary = 19,
TeamLeader = 20,
LoanProductConfigurator = 21,
Realtor = 23,
BrokerDisclosures = 25,
LoanOpener = 26,
}

export enum RoleTypeEnum {
None = 0,
LoanAssignments = 1,
}

export enum SearchCriteriaAmortizationTypeEnum {
Fixed = 1,
ARM = 2,
}

export enum SearchCriteriaARMFixedTermEnum {
ARM10yr = 1,
ARM7yr = 2,
ARM5yr = 4,
ARM3yr = 8,
ARM2yr = 16,
ARM1yr = 32,
ARM6mo = 64,
ARM3mo = 128,
ARM1mo = 256,
ARM15yr = 512,
}

export enum SearchCriteriaCustomTermEnum {
Loan8yr = 1,
Loan9yr = 2,
Loan11yr = 4,
Loan12yr = 8,
Loan13yr = 16,
Loan14yr = 32,
Loan16yr = 64,
Loan17yr = 128,
Loan18yr = 256,
Loan19yr = 512,
Loan21yr = 1024,
Loan22yr = 2048,
Loan23yr = 4096,
Loan24yr = 8192,
Loan26yr = 16384,
Loan27yr = 32768,
Loan28yr = 65536,
Loan29yr = 131072,
}

export enum SearchCriteriaDesiredProductOptionEnum {
Price = 0,
Rate = 1,
}

export enum SearchCriteriaLoanTermEnum {
Loan40yr = 1,
Loan30yr = 2,
Loan25yr = 4,
Loan20yr = 8,
Loan15yr = 16,
Loan10yr = 32,
Loan7yr = 64,
Loan5yr = 128,
Loan4yr = 256,
Loan2yr = 512,
Loan29yr = 1024,
Loan28yr = 2048,
Loan27yr = 4096,
Loan26yr = 8192,
Loan24yr = 16384,
Loan23yr = 32768,
Loan22yr = 65536,
Loan21yr = 131072,
}

export enum SearchCriteriaLoanTypeEnum {
Conforming = 1,
NonConforming = 2,
FHA = 4,
USDA = 8,
HARP_FannieMae = 16,
HARP_FreddieMac = 32,
}

export enum SearchCriteriaTypeEnum {
None = 0,
LoanTypeList = 1,
AmortizationType = 2,
LoanTerms = 3,
ArmTerms = 4,
}

export enum SecondMortgageInfoTypeEnum {
OpenedAtTimeOfPurchase = 0,
OpenedInLast12Months = 1,
OpenedOver12MonthsAgo = 2,
None = -1,
}

export enum SectionTypeEnum {
Empty = 0,
BorrowerDebits = 1,
BorrowerCredits = 2,
SellerCredits = 3,
SellerDebits = 4,
}

export enum SecurelinkEmailTemplateSignatureLineEnum {
UserName = 1,
}

export enum SecurelinkEmailTemplateStatusEnum {
InActive = 0,
Active = 1,
}

export enum SecureLinkEnum {
Unknown = 0,
SignMyDocuments = 1,
ConfirmReceiptOfAppraisal = 2,
CompleteLoanApplication = 3,
OrderAppraisal = 4,
ViewMyNeedsList = 5,
MiscellaneousDocuments = 6,
SignInitialDisclosures = 7,
SignBrokerDisclosures = 8,
AdverseDocument = 9,
SignMyPreDisclosures = 10,
ReviewMyDocuments = 11,
ESignMyDocuments = 12,
}

export enum SecurityLevelTypeEnum {
Public = 1,
Internal = 2,
}

export enum SelectedDisclosureDocumentTypeEnum {
None = 0,
LoanType = 1,
LoanApplicationType = 2,
BorrowerType = 3,
}

export enum SelectOneYesNoEnum {
SelectOne = 0,
Yes = 1,
No = 2,
}

export enum SellerTypeEnum {
Individual = 0,
Bank = 1,
LLC = 2,
}

export enum SelfEmploymentEntityTypeEnum {
None = -1,
CCorp = 0,
LLCCCorp = 1,
LLCPartnership = 2,
LLCSCorp = 3,
LLCSoleProprietorship = 4,
Partnership = 5,
SCorp = 6,
SoleProprietorship = 7,
}

export enum SelfEmploymentCloverEntityTypeEnum {
None = 0,
CCorp = 1,
Partnership = 2,
SCorp = 3,
SoleProprietorship = 4,
LLC = 5,
}

export enum SelfEmploymentCloverEntityTypeLLCEnum {
None = 0,
CCorp = 1,
Partnership = 2,
SCorp = 3,
SoleProprietorship = 4,
}

export enum ServiceProviderEnum {
BorrowerSelected = 0,
Affiliate = 1,
SSP = 2,
}

export enum SigningStatusEnum {
None = 0,
Decline = 1,
Signed = 2,
}

export enum SignOnTypeEnum {
Internal_iMP = 1,
External_iDP = 2,
}

export enum SimulatorIntegrationTypeEnum {
None = 0,
Disclosures = 1,
OBAPI = 2,
}

export enum SortDirectionEnum {
Ascending = 1,
Descending = 2,
}

export enum StructuretypeEnum {
None = 0,
Existing = 1,
New = 2,
}

export enum SubSectionTypeEnum {
Empty = 0,
Main = 1,
PaidAdjustments = 2,
UnpaidAdjustments = 3,
OtherCredits = 4,
Adjustments = 5,
AdditionalAdjustments = 6,
Payoffs = 7,
SellerCredit = 8,
}

export enum SyncStatusEnum {
Pending = 0,
InProgress = 1,
Completed = 2,
}

export enum TaxInfoSectionEnum {
Personal = 1,
Business = 2,
PersonalAndBusiness = 3,
}

export enum TaxTranscriptRequestActionType {
Submit = 5,
StatusQuery = 15,
Retrieve = 25,
}

export enum TitleCostIncludedTypeEnum {
None = 0,
IncludeInLendersPolicy = 1,
IncludeInOwnersPolicy = 2,
ShowAsALineItem = 3,
}

export enum TitleVestedAsEnum {
None = 0,
Veteran = 1,
VeteranAndSpouse = 2,
Other = 3,
JointVeteranAndVeteran = 4,
JointVeteranAndNonVeteran = 5,
}

export enum ToleranceDisclosureTypeEnum {
Disclosure = 0,
ReDisclosure = 1,
Lock = 2,
}

export enum TotalPaidByEnum {
Unknown = 0,
LoanOfficer = 1,
Division = 2,
Company = 3,
Borrower = 4,
}

export enum TracedWorkflowStatusEnum {
None = 0,
Requested = 1,
InProgress = 2,
Completed = 3,
Failed = 4,
Approved = 5,
Rejected = 6,
Finished = 7,
}

export enum TracedWorkflowTypeEnum {
None = 0,
Pricing = 1,
Disclosures = 2,
Locking = 3,
AUS = 4,
}

export enum TranscriptOrderStatusEnum {
Unassigned = 0,
New = 1,
Pending = 2,
Completed = 3,
Canceled = 4,
Rejected = 5,
Error = 6,
}

export enum TranscriptRequestedBusinessEnum {
_1065 = 2,
_1120 = 3,
_1120S = 4,
_1041 = 5,
_990 = 6,
}

export enum TranscriptRequestedIndividualEnum {
None = 0,
_1040 = 1,
}

export enum TranscriptTypeEnum {
None = 0,
ReturnTranscript6a = 1,
AccountTranscript6b = 2,
RecordOfAccount6c = 3,
}

export enum TX50a6AllowableTypeEnum {
Undefined = 0,
IncludedIn2Percent = 1,
NotIncludedIn2Percent = 2,
NotAllowed = 3,
}

export enum UlddProjectTypeEnum {
EstablishedCoop = 1,
FannieMaeApprovedCoop = 2,
EEstablishedPUD = 3,
FNewPUD = 4,
GNotInDevelopment = 5,
PLimitedReviewNewProject = 6,
QLimitedReviewEstablishedProject = 7,
RExpeditedReviewNewProject = 8,
SExpeditedReviewEstablishedProject = 9,
TFannieMae1028Review = 10,
UFHAApprovedProject = 11,
VCondoRefiPlus = 12,
}

export enum UnitDesignatorTypeEnum {
SelectOne = 0,
Apartment = 1,
Basement = 2,
Building = 4,
Condo = 8,
Department = 16,
Floor = 32,
Front = 64,
Hangar = 128,
Key = 256,
Lobby = 512,
Lot = 1024,
Lower = 2048,
Office = 4096,
Penthouse = 8192,
Pier = 16384,
Rear = 32768,
Room = 65536,
Side = 131072,
Space = 262144,
Stop = 524288,
Suite = 1048576,
Trailer = 2097152,
Unit = 4194304,
Upper = 8388608,
}

export enum UpfrontPreferredPaymentPeriodEnum {
None = 0,
Financed = 1,
InCash = 2,
}

export enum UploadedFileStatusEnum {
None = 0,
Uploaded = 1,
InReview = 2,
Received = 3,
Faxed = 4,
Removed = 5,
Rejected = 6,
Needed = 7,
Exported = 8,
ExportFailed = 9,
Delivered = 10,
Signed = 11,
Sent = 12,
Pending = 13,
Submitted = 14,
Approved = 15,
Obsolete = 20,
Accepted = 21,
AutoClassifying = 22,
AutoClassifiedInReview = 23,
}

export enum URLAFormTypeEnum {
Unspecified = 0,
URLA2009 = 1,
URLA2020 = 2,
}

export enum URLALoanAppVersionEnum {
Unspecified = 0,
URLADefault = 1,
URLA2020 = 2,
}

export enum US_StateEnum {
Alabama = 0,
Alaska = 1,
Arizona = 2,
Arkansas = 3,
California = 4,
Colorado = 5,
Connecticut = 6,
Delaware = 7,
Florida = 8,
Georgia = 9,
Hawaii = 10,
Idaho = 11,
Illinois = 12,
Indiana = 13,
Iowa = 14,
Kansas = 15,
Kentucky = 16,
Louisiana = 17,
Maine = 18,
Maryland = 19,
Massachussets = 20,
Michigan = 21,
Minnesota = 22,
Mississippi = 23,
Missouri = 24,
Montana = 25,
Nebraska = 26,
Nevada = 27,
NewHamshire = 28,
NewJersey = 29,
NewMexico = 30,
NewYork = 31,
NorthCarolina = 32,
NorthDakota = 33,
Ohio = 34,
Oklahoma = 35,
Oregon = 36,
Pennsylvania = 37,
RhodeIsland = 38,
SouthCarolina = 39,
SouthDakota = 40,
Tennessee = 41,
Texas = 42,
Utah = 43,
Vermont = 44,
Virginia = 45,
Washington = 46,
WestVirginia = 47,
Wisconsin = 48,
Wyoming = 49,
DistrictOfColumbia = 50,
Undefined = -1,
}

export enum UserAccountRelationshipTypeEnum {
BuyersAgentToLoanOfficer = 1,
None = -1,
}

export enum UserAccountStatusEnum {
DoesNotExist = 0,
Active = 1,
Inactive = 2,
}

export enum VaAllowableTypeEnum {
None = 0,
NonAllowable = 1,
Allowable = 2,
NonAllowableToOnePercent = 3,
}

export enum VACalculatorsEnum {
VAGeneralInformation = 1,
IRRLMax = 2,
IRRLQMCertification = 3,
VALoanAnalysis = 4,
VACashOutNTB = 5,
}

export enum VAFeeLimitExceededReasonEnum {
None = 0,
UnAllowableFeeCharged = 1,
FeesExceedsOnePercentLimit = 2,
}

export enum VALoanPurposeEnum {
None = 0,
PurchaseHomePreviouslyOccupied = 1,
FinanceImprovement = 2,
Refinance = 3,
PurchaseNewCondoUnit = 4,
PurchaseExistingCondoUnit = 5,
PurchaseHomeNotPreviouslyOccupied = 6,
ConstructHome = 7,
FinanceCoopPurchase = 8,
PurchaseManufacturedHome = 9,
PurchaseManufacturedHomeAndLot = 10,
RefiManufacturedHomeToBuyLot = 11,
RefiManufacturedHomeOrLotLoan = 12,
}

export enum ValuationConsentEnum {
None = 0,
AwareOfTheValuation = 1,
NotAwareOfTheValuation = 2,
}

export enum VaNtbBenefitEnum {
LowerPaymentCheckbox = 1,
EliminateMICheckbox = 2,
ShorterTermCheckbox = 3,
LowerInterestRateCheckbox = 4,
LowerLoanAmountCheckbox = 5,
ARMtoFixCheckbox = 6,
ResidualCheckbox = 7,
ConstructionCheckbox = 8,
}

export enum VARegionsEnum {
None = 0,
Northeast = 1,
Midwest = 2,
South = 3,
West = 4,
}

export enum VARequirementsEnum {
Incomplete = 0,
NotSatisfied = 1,
Satisfied = 2,
}

export enum VendorTypeEnum {
None = 0,
ChicagoTitle = 1,
LaywersTitle = 2,
WFG = 3,
WFGLS = 4,
InformativeResearch = 5,
ComplianceEase = 6,
DataVerify = 7,
OptimalBlue = 8,
IntegrationTemplate = 9,
CoreLogicCredCo = 10,
FannieMae = 11,
FreddieMac = 12,
AccountChek = 13,
Partners = 14,
EquifaxTriMerge = 15,
Freedom = 16,
Emae = 17,
DocuTech = 18,
DigitalDocs = 19,
Cimmaron = 20,
Mercury = 21,
DocuSign = 22,
MeridianLink = 29,
Arch = 30,
Radian = 31,
LLPower = 32,
AuthorizeDotNet = 33,
WestVM = 34,
Melissa = 35,
MGIC = 38,
FAM = 39,
ARIVE = 40,
MISMO34 = 41,
CBCInnovis = 42,
Envoy = 43,
FactualData = 44,
Avantus = 45,
CloudvirgaPricing = 46,
SunWest = 47,
TWN = 48,
}

export enum ViewModelFunctionStatusEnum {
Success = 0,
SectionKeyDoesNotExist = 1,
NotDoubleEntry = 2,
SubSectionIsFull = 3,
GeneralFailure = 4,
LineNumberDoesNotExist = 5,
LinkedDoubleEntryCannotBeUnlinked = 6,
LineNumberIsInUse = 7,
SubSectionKeyDoesNotExist = 8,
SubSectionKeyCounterpartDoesNotExist = 9,
MainSubSectionCannotBeAltered = 10,
ValueIsReadOnly = 11,
NameIsReadOnly = 12,
OtherCostNameStringIsNull = 13,
SubSectionIsSingleSidedOnly = 14,
SubSectionIsDoubleSidedOnly = 15,
NameNotInDictionary = 16,
EntryCannotBeRemoved = 17,
}

export enum ViewModelStatus {
Unchanged = 1,
New = 2,
Modified = 3,
Deleted = 4,
}

export enum WorkflowEnum {
DefaultWorkflow = 0,
FhaCashOutWorkflow = 1,
VaCashOutWorkflow = 2,
ConventionalCashoutWorkflow = 3,
ConventionalPreapprovalWorkflow = 4,
ConventionalPurchaseWorkflow = 5,
ConventionalRateAndTermWorkflow = 6,
FHAPreapprovalWorkflow = 7,
FHAPurchaseWorkflow = 8,
FHARateAndTermWorkflow = 9,
FHAStreamlineCreditQualWorkflow = 10,
FHAStreamlineNonCreditQualWorkflow = 11,
USDAPreapprovalWorkflow = 12,
USDAPurchaseWorkflow = 13,
USDAStreamlineAssistWorkflow = 14,
VAIRRRLCreditQualWorkflow = 15,
VAIRRRLNonCreditQualWorkflow = 16,
VAPreapprovalWorkflow = 17,
VAPurchaseWorkflow = 18,
URLA2009 = 20,
URLA2020 = 21,
}

export enum WorkflowFieldTypeEnum {
BooleanType = 1,
StringType = 2,
NumericType = 3,
}

export enum SingleUserLockTypeEnum {
Exclusive = 0,
LimitedActions = 1,
}
